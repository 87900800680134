export const en = {
  no: 'No',
  yes: 'Yes',
  cancel: 'Cancel',
  generell_clipboard_copy: 'Click to copy',
  generell_clipboard_copy_done: 'Copied',
  close: 'Close',
  statuspage: 'status page',
  status_pending: 'Pending',
  now: 'Now',
  notification_center_placeholder: 'No notifications at the moment.',
  notification_center_release_notes_label: 'Release notes',
  notification_center_release_notes_subscribe: 'Subscribe',
  maintenance_incidents: 'Maintenance & Incidents',
  incident_scheduled: 'scheduled',
  incident_investigating: 'investigating',
  incident_identified: 'working on',
  incident_watching: 'watching',
  goto_statuspage: 'Go to statuspage',
  release_notes_all: 'All release notes on our website',
  send: 'Send',
  januar: 'january',
  january: 'january',
  februar: 'february',
  february: 'february',
  märz: 'march',
  march: 'march',
  april: 'april',
  mai: 'may',
  may: 'may',
  juni: 'june',
  june: 'june',
  juli: 'july',
  july: 'july',
  august: 'august',
  september: 'september',
  oktober: 'october',
  october: 'october',
  november: 'november',
  dezember: 'december',
  december: 'december',
  usage_limit_warning_headline: 'Account Limit Warning',
  usage_limit_warning_headline_overview: 'Account Limits Overview',
  usage_limit_warning_info_pre: 'You can view account limits ',
  usage_limit_warning_info_link: 'here',
  usage_limit_warning_info_post: ', or contact us to increase them',
  platformstatus: 'Platform status',
  platformstatus_online: 'Platform is online',
  platformstatus_performance_issues: 'Performance issues',
  platformstatus_performance_issues_long:
    'We are currently experiencing performance issues',
  platformstatus_partial_outage: 'Partial outage',
  platformstatus_partial_outage_long: 'Parts of the platform are down',
  platformstatus_major_outage: 'Major outage',
  platformstatus_major_outage_long: 'Major parts of the platform are down',
  limit_capacity_total: 'Capacity',
  limit_capacity_total_local: 'Rocket Storage Capacity',
  limit_cores_total: 'Cores',
  limit_ipv4_total: 'IPv4',
  limit_ipv6_total: 'IPv6',
  limit_loadbalancers_total: 'Load Balancers',
  limit_memory_total: 'Memory',
  limit_object_storage_buckets_total: 'Object Storage Buckets',
  limit_object_storage_user_quota: 'Object Storage',
  limit_paas_security_zones_total: 'Security Zones',
  limit_snapshots_total: 'Snapshots',
  limit_snapshots_total_local: 'Rocket Storage Snapshots',
  limit_autoscale_info:
    'We automatically scale your limits to ensure the best balance of security and scalability.',
  set_default: 'Reset to default table layout',
  uebernehmen: 'Apply',
  none: 'None',
  table_settings_headline: 'Layout of your list',
  table_settings_intro:
    'Here you can create your personal layout for this table. The changes will be saved immediately.',
  sort_asc: 'Sort ascending',
  sort_desc: 'Sort descending',
  delete_confirm_headline:
    'Permanently delete {{ objectType }} "{{ objectName }}"?',
  remove_confirm_headline: 'Remove {{ objectType }} "{{ objectName }}"?',
  delete_confirm_keep: 'Keep',
  remove_confirm_keep: 'Keep',
  delete_confirm_delete: 'Delete {{ objectType }}',
  remove_confirm_delete: 'Remove {{ objectType }}',
  delete_confirm_type_to_confirm_label_pre: 'Please type',
  delete_confirm_type_to_confirm_label_post: 'to continue',
  delete_confirm_critical_warning: 'This action can not be undone.',
  delete_confirm_selected: 'Selected',
  delete_confirm_objects: 'Objects',
  delete_confirm_objects_singular: 'Object',
  delete_confirm_deselect_all: 'Deselect all',
  delete_confirm_select_all: 'Select all',
  copy_uuid: 'Copy UUID',
  form_required_hint: '* Required field',

  HELP_CENTER_HEADLINE: 'How can we help you?',
  HELP_CENTER_SUBHEADLINE:
    'Use one of our digital help options to find help for your problem.',
  HELP_CENTER_SEARCHHEADLINE: 'Take a look at our tutorials',
  HELP_CENTER_PLACEHOLDER: 'Search for topics or products',
  HELP_CENTER_EMPTY: 'Sorry, we could not find a suitable tutorial',
  HELP_CENTER_BOX_1_HEADLINE: 'Platform Status',
  HELP_CENTER_BOX_1_TEXT:
    'Check the current status of the panels on our status page.',
  HELP_CENTER_BOX_1_LINK: 'Status page',
  HELP_CENTER_BOX_1_TARGET: 'https://status.gridscale.io/',
  HELP_CENTER_BOX_2_HEADLINE: 'Product documentation',
  HELP_CENTER_BOX_2_TEXT:
    'Visit our comprehensive and simple description of our digital products.',
  HELP_CENTER_BOX_2_LINK: 'Product documentation',
  HELP_CENTER_BOX_2_TARGET: 'https://my.gridscale.io/product-documentation/',
  HELP_CENTER_BOX_3_HEADLINE: 'Frequently asked questions',
  HELP_CENTER_BOX_3_TEXT:
    'Check out our answers to the most frequently asked questions.',
  HELP_CENTER_BOX_3_LINK: 'FAQ',
  HELP_CENTER_BOX_3_TARGET: 'https://gridscale.io/community/faq/',
  HELP_CENTER_SUPPORT_HEADLINE: 'Support in 3 simple steps',
  HELP_CENTER_SUPPORT_SUBHEADLINE:
    'Do you have a problem that you can’t find the answer to? Just ask us and we will help you as soon as possible.',
  HELP_CENTER_STEP_1_1: 'Explain your problem',
  HELP_CENTER_STEP_1_2:
    'Please be as precise as possible, so that we can help you as soon as possible. If necessary, please describe your problem in more detail using screenshots etc.',
  HELP_CENTER_STEP_2_1: 'Verify your ticket',
  HELP_CENTER_STEP_2_1_2: '(and escalate it if needed).',
  HELP_CENTER_STEP_2_2:
    "We'll send you an email asking for confirmation to make sure we're solving real customer issues. If it is urgent, you can also escalate the ticket if critical infrastructure is affected.",
  HELP_CENTER_STEP_3_1: 'Get help from us immediately!',
  HELP_CENTER_STEP_3_2:
    'We will get in touch with you by mail or phone if requested. Together we can do it!',
  HELP_CENTER_CTA: 'Request Support',
  HELP_CENTER_EMAIL: 'support@gridscale.io',
  HELP_CENTER_EMAIL_PARTNER: 'partner@gridscale.io',
  HELP_CENTER_LOADING: 'We are looking for suitable tutorials for you ...',
  HELP_CENTER_SHOW_ALL: 'To all tutorials',
  RESET_TO_DEFAULT: 'Reset to default',

  got_it: 'Got it!',
  hotspot_deactivate: 'Don’t show any more tips!',
  hotspot_family_deactivate: 'Don’t show any more tips!',

  nps_label_low: 'Very unlikely',
  nps_label_high: 'Very likely',
  nps_question: 'How likely are you to recommend our product to others?',
  nps_comment_question_10:
    'We love positive feedback, what makes you rate us so highly?',
  nps_comment_question_not_10:
    'What could we do to increase your vote by just 1 point?',
  nps_thanks_header: 'Thank you for your feedback!',
  nps_thanks_body:
    'We are constantly working to improve our product. You have been a great help for this. Thank you.',

  location_explorer_headline: 'Location Explorer',
  location_explorer_locations: 'Locations',
  location_explorer_show_all: 'Show all',
  location_explorer_show_less: 'Show less',
  location_explorer_allow_usage: 'Share location for accounts',
  location_explorer_intro:
    'Here you can see all the locations where you can create resources. Use the filters or the map to learn more about each location.',
  location_explorer_intro_p:
    'Get the location information you need and decide which locations to share with your accounts.',
  location_explorer_filter_title: 'Filters',
  location_explorer_filter_enabled: 'Sharing',
  location_explorer_filter_enabled_enabled: 'Shared Location',
  location_explorer_filter_enabled_disabled: 'Location not shared',
  location_explorer_filter_country: 'Location',
  location_explorer_filter_country_de: 'Germany',
  location_explorer_filter_country_fr: 'France',
  location_explorer_filter_country_ch: 'Swiss',
  location_explorer_filter_country_at: 'Austria',
  location_explorer_filter_country_nl: 'Netherlands',
  location_explorer_filter_features: 'Features',
  location_explorer_filter_features_has_rocket_storage: 'Rocket Storage',
  location_explorer_filter_features_has_server_provisioning:
    'Server Provisioning',
  location_explorer_filter_features_object_storage_region: 'Object Storage',
  location_explorer_filter_partner_certification: 'Certification',
  location_explorer_filter_operator: 'Operator',
  location_explorer_filter_operator_certification_list:
    'Operator Certification',
  location_explorer_filter_reset: 'Reset Filter',
  location_explorer_details: 'Details',
  location_explorer_owner: 'Owner',
  location_explorer_website: 'Website',
  location_explorer_empty_headline: 'No locations found',
  location_explorer_empty_text:
    'We are sorry, we could not find a location with the filters set. We are constantly working to unlock new locations for our customers. In the meantime you can try your luck again with other filters.',
  location_explorer_leaflet_gesturehandling_touch:
    'Use two fingers to zoom the map',
  location_explorer_leaflet_gesturehandling_scroll:
    'Use ctrl + scroll to zoom the map',
  location_explorer_leaflet_gesturehandling_scrollMac:
    'Use \u2318 + scroll to zoom the map',
  PASSWORD_PROMPT: 'Choose a secure password',
  PASSWORD_STRENGTH: 'Strength',
  PASSWORD_STRENGTH_WEAK: 'Creepy 😨',
  PASSWORD_STRENGTH_MEDIUM: 'Fair enough 😐',
  PASSWORD_STRENGTH_STRONG: 'Strong 😎',
  BACKGROUND_JOBS: 'Background jobs',
  BACKGROUND_JOBS_REMOVE_FINISHED: 'Remove completed jobs',
  BACKGROUND_JOBS_REMOVE_ALL: 'Hide all jobs',
  MINIMIZE: 'Minimize',
  MAXIMIZE: 'Maximize',
  PRODUCT: 'Product',
  PRICE: 'Price',
  PRICE_FOR_LOCATION: 'Price for location {{ locationName }}',

  PRODUCT_DOC: 'Product Documentation',
  API_DOC: 'API Documentation',
  FURTHER_INFORMATION: 'You can find more information here:',

  DETAILS: {
    NAVIGATE_TO: 'Navigate to {{ objectType }} "{{ name }}"',
    NAVIGATE_PREV: 'Navigate to previous object',
    NAVIGATE_NEXT: 'Navigate to next object',
    NO_ACTIONS_WHILE_LOADING: 'No action can be performed during provisioning.',
  },
  PRICEBOX: {
    DEPRECATED_TOOLTIP: 'Discontinued:  We do not offer any further updates for this product. Our recommendation is therefore to upgrade to a more recent version. Until then, billing will be at the usual price. You can find out more in our [Product documentation](/docs).',
    PRICE_STATIC: 'Price per',
    PRICE_NEW_STATIC: 'New Price per',
    DAY: 'Day',
    MONTH: 'Month',
    HOUR: 'Hour',
    UPDATE: 'Update',
    ACTUAL: 'Current',
    REALTIMESCALING_MINIMUM: 'Usage-based, al.',
    REALTIMESCALING: 'Usage-based',
    CHANGES: 'adjustments',
    REVERT: 'discard changes',
    CONFIGURATION: 'configuration',
    PRODUCT: 'product',
    AMOUNT: 'Quantity',
    CHOICE: 'Choice',
    SUM_PER_DAY: 'Total / day',
    SUM_PER: 'Total /',
    MEMORY: 'RAM',
    CORE: 'Cores',
    STORAGE: 'Distributed Storage Standard I/O',
    STORAGE_HIGH: 'Distributed Storage High I/O',
    STORAGE_INSANE: 'Distributed Storage Insane I/O',
    STORAGE_LOCAL: 'Rocket Storage',
    LABELS: 'Labels',
    INCLUSIVE: 'Included',
    CHANGES_WITHOUT_PRICE_EFFECT: 'Changes without price impact',
    PRICE_PER_DAY: 'Price per day',
    PRICE_PER_MONTH: 'Price per month',
    PRICE_NEW_PER_DAY: 'New Price per day',
    PRICE_NEW_PER_MONTH: 'New Price per month',
    CREATE: 'Create',
    PAAS_CORE: 'CPU Core',
    PAAS_STORAGE: 'Storage',
    PAAS_REALTIME_STORAGE_STANDARD:
      'Realtime Scaling Storage Standard Performance',
    PAAS_REALTIME_STORAGE_HIGH: 'Realtime Scaling Storage High Performance',
    PAAS_REALTIME_STORAGE_INSANE: 'Realtime Scaling Storage Insane Performance',
    BASE_FEE: 'Base fee',
    MINIMUM_CONFIGURATION: 'Minimum configuration',
    SUBTOTAL: 'Subtotal',
    TOOLTIP_DISCOUNTED:
      'Discounted price until {{ discountEndDate }} - after that {{ regularPrice }}',
    TOOLTIP_DISCOUNTED_WITH_CHANGES:
      'Discounted price until {{ discountEndDate }} - after that {{ regularPrice }} respectively {{ regularPriceWithChanges }} after your changes',
    ISOIMAGE: 'ISO-Image',
    READY_TO_CREATE: 'Ready to be created',
    READY_TO_CREATE_TOOLTIP:
      'This {{ objectName }} will be created together with the {{ objectNameParent }}.',
    ALREADY_CREATED: 'Already created',
    ALREADY_CREATED_TOOLTIP:
      'This object was already creating during the create process of this {{ objectNameParent }}. If you do not need the {{ objectNameParent }} you need to delete it manually.',
    AT_LEAST_ABBR: 'al.',
    COST_MODEL_USAGE_INFO:
      'This product is billed "usage based". This means you only pay for what you actually use - to the minute.',
    COST_MODEL_REALTIME_INFO:
      'This product uses real-time scaling, which automatically adjusts hardware usage to the demands of your application. Accordingly, the costs also scale according to the required resources. We show you the prices for a maximum demand.',
    BILLING_TYPE_TEXT_REALTIME_SCALING: 'with Realtime Scaling',
    BILLING_TYPE_TOOLTIP_REALTIME_SCALING:
      'With real-time scaling, your services start with the minimum configuration and increase automatically as needed. We show you the minimum and maximum price.',
    VARIABLE_COSTS: 'Variable costs (on demand)',
    AUTOSCALING_PRODUCT_LABELS: {
      paas_core: 'CPU Cores',
      paas_storage: 'Storage',
      paas_realtime_storage_standard:
        'Realtime Scaling Storage Standard Performance',
      paas_realtime_storage_high: 'Realtime Scaling Storage High Performance',
      paas_realtime_storage_insane:
        'Realtime Scaling Storage Insane Performance',
    },
    AUTOSCALING_AMOUNT_SUFFIXES: {
      paas_core: 'Cores',
      paas_storage: 'GB',
      paas_realtime_storage_standard: 'GB',
      paas_realtime_storage_high: 'GB',
      paas_realtime_storage_insane: 'GB',
    },
  },
  PRICELIST: {
    COMPARE_LOCATION: 'Compare to location',
    FREE: 'free',
    PRODUCT_NOT_AVAILABLE: 'Product not available on this location',
    DISCONTINUED_TOOLTIP:
      'This product is "discontinued", which means that existing cloud resources continue to exist, but no new ones can be created.<br /><a href="/product-documentation/cloud-computing/enterprise-cloud/servicelevelagreement/#discontinued" target="_blank">Learn more<i class=\'icon-a106_linkextern\'></i></a>',
    EXPAND_ALL_SECTIONS: "Expand all sections",
    COLLAPSE_ALL_SECTIONS: "Collapse all sections",
    GROUP_LABELS: {
      CPU_RAM: 'CPU & RAM',
      GPU: 'GPU',
      SERVER: 'Server',
      STORAGE: 'Storage',
      DISTRIBUTED_STORAGE: 'Distributed Storage',
      ROCKET_STORAGE: 'Rocket Storage',
      TEMPLATES_WINDOWS: 'Templates: Windows',
      STORAGE_FUNCTIONS: 'Storage functions',
      OBJECT_STORAGE: 'Object Storage',
      NETWORK: "Network",
      LOADBALANCER: "Loadbalancer",
      IP_ADRESSES: "IP adresses",
      PAAS: "PaaS",
      PAAS_INFO: "Most of our platform services work with real-time scaling. This means that the services scale automatically and (in addition to the basic fee and the costs of the minimum configuration) only the costs for the resources used are incurred.  You configure the basic fee for your services in the corresponding service section, the minimum configuration is made up of CPU and storage differently for each service - you determine these prices in the 'Realtime Scaling' section.",
      PAAS_INFO_COMPARE_MODE: 'Most of our platform services work with real-time scaling. This means that the services scale automatically and (in addition to the basic fee and the costs of the minimum configuration) only the costs for the resources used are incurred.',
      REALTIME_SCALING: "Realtime Scaling",
      REALTIME_SCALING_INFO: "We charge a minimum configuration for our real-time scaling services, which you can view at the corresponding services. Your Realtime Scaling prices are used for both the minimum configurations and the actual use of the resources.",
      GRIDSQL: "gridSQL",
      GRIDSQL_INFO: "All our gridSQL services include real-time scaling, with different minimum configurations: SQL Server has a minimum configuration of 1 GB of storage, while MariaDB, MySQL and PostgreSQL are charged at 1 core and 1 GB each. You can also set discounted prices for SQL Server for the first 30 days.",
      GRIDFS_INFO_TOOLTIP: "The price for the first GB is already due from 0.000001 GB storage space.",
      GRIDSTORE: "gridStore",
      GRIDSTORE_INFO: "The prices for our gridStore services are calculated differently: While Memcached is equipped with real-time scaling, you can set fixed prices for Redis Cache and Redis Store.",
      GRIDFS: "gridFs",
      GRIDFS_INFO: "We do not charge a minimum configuration for gridFs and real-time scaling is not necessary - your customers pay per GB of storage space.",
      VIRTUAL_LOCATION: "Virtual Location",
      DEDICATED_NODES: "Dedicated Nodes",
      OTHER: "Other"
    },
    PAAS_FLAVOR_HEADLINES: {
      postgres: 'Flavor: PostgreSQL',
      mysql: 'Flavor: MySQL',
      mariadb: 'Flavor: MariaDB',
      mssql_discounted: 'Flavor: SQL Server (*for the first 30 days)',
      mssql_discounted_TOOLTIP: 'We offer our customers a 50% discount on their SQL Server for the first 30 days. You can do the same or enter the price of your choice. The first 30 days will be charged at the prices in this section.',
      mssql: 'Flavor: SQL Server'
    },
    PER: "per",
    MONTH: "month",
    LICENCE: "Licence",
    PROVISIONAL_TOTAL: "Provisional total",
    PROVISIONAL_TOTAL_INTRO1: "The provisional total price is made up as follows:",
    PROVISIONAL_TOTAL_BASEFEE: "Base fee",
    PROVISIONAL_TOTAL_OUTRO: "With real-time scaling, only the costs for the resources used are added later.",
    PRIVATE_TEMPLATE: "Private template",
    accounts_settings_prices_location: 'Show prices for following location:',
    prices_reset_field: 'Reset to default from price list',
  },
  price_discount_calculator_intro:
    'Use the discount calculator to add a discount in % to all prices',
  price_discount_calculator: 'Discount calculator',
  price_template_save_discount: 'Save template',
  partner_price: 'Purchase price',
  account_price: 'Sales price',
  GENERAL: {
    MORE: 'Mehr',
    HOTSPOT_PRICE_BOX:
      "We've created more space for you in the Create dialogs and repositioned the price box. Open it to see the new layout.",
    PER: 'per',
    DISABLED_ROLE_BELOW_WRITE: "You don't have write permission.",
    FIND_OUT_MORE: 'Find out more',
    PAGE_NOT_FOUND: 'Page not found',
    PAGE_NOT_FOUND_TEXT:
      "We're so sorry! We have searched everywhere, but we can't find the page.",
    GO_BACK: 'Back',
  },
  missing_rights_to_edit: 'You lack the permission to edit',
  ZERO_OR_MANY_RESULT: '{{ number }} Results',
  ONE_RESULTS: '{{ number }} Result',

  TABLE_GEN_3: {
    SETTINGS_MODAL: {
      HEADLINE: 'Adjust table',
      INTRO:
        'Here you can create your personal layout for this table. The changes will be saved immediately.',
      RESET: 'Reset to default table layout',
      CONFIRM: 'Apply',
      NONE: 'None',
    },
    SORT: {
      ASC: 'Sort ascending',
      DESC: 'Sort descending',
    },
    FAVORITES: {
      LABEL: 'Favorites',
      MAKE: 'Make favorite',
      SELECTED: 'selected',
      SELECTED_SINGLE: 'selected',
      CLOSE: 'Cancel',
    },
    FILTER: {
      PLACEHOLDER: 'Filter',
      EMPTY_HEADLINE: 'No {{searchables}} found',
      EMPTY: 'Check your input or start a new search',
    },
  },

  DISRUPTORS: {
    BUTTON_NEXT: 'Next',
    BUTTON_UNDERSTOOD: 'Understood',
  },

  PRODUCT_LABEL: {
    NEW: 'New',
    DEPRECATED: 'Deprecated',
    DISCONTINUED: 'Discontinued',
    RECOMMENDED: 'Recommended',
    LABS: 'Labs',
    UPGRADE: 'Upgrade',
    FREE: 'Free',
    REALTIME_SCALING: 'Realtime Scaling!'
  },

  LOCATION_EXPLORER_2: {
    HEADLINE: 'Location Explorer',
    INTRO_PARTNER:
      'Get the location information you need and decide which locations to share with your accounts.',
    INTRO_PUBLIC:
      'Here you can see all the locations where you can create resources. Use the filters or the map to learn more about each location.',
    GESTURE_HANDLING_TOUCH: 'Use two fingers to zoom the map',
    GESTURE_HANDLING_SCROLL: 'Use Ctrl + Scroll to zoom the map',
    GESTURE_HANDLING_SCROLL_MAC: 'Use \u2318 + scroll to zoom the map',
    FILTER: {
      HEADLINE: 'Filter',
      SHOW_MORE: 'Show all',
      SHOW_LESS: 'Show less',
      COUNTRY: 'Country',
      COUNTRIES: {
        LABEL: 'Country',
        "AF": "Afghanistan",
        "AL": "Albania",
        "DZ": "Algeria",
        "AS": "American Samoa",
        "AD": "Andorra",
        "AO": "Angola",
        "AI": "Anguilla",
        "AQ": "Antarctica",
        "AG": "Antigua and Barbuda",
        "AR": "Argentina",
        "AM": "Armenia",
        "AW": "Aruba",
        "AU": "Australia",
        "AT": "Austria",
        "AZ": "Azerbaijan",
        "BS": "Bahamas",
        "BH": "Bahrain",
        "BD": "Bangladesh",
        "BB": "Barbados",
        "BY": "Belarus",
        "BE": "Belgium",
        "BZ": "Belize",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BT": "Bhutan",
        "BO": "Bolivia",
        "BA": "Bosnia and Herzegovina",
        "BW": "Botswana",
        "BV": "Bouvet Island",
        "BR": "Brazil",
        "IO": "British Indian Ocean Territory",
        "BN": "Brunei Darussalam",
        "BG": "Bulgaria",
        "BF": "Burkina Faso",
        "BI": "Burundi",
        "KH": "Cambodia",
        "CM": "Cameroon",
        "CA": "Canada",
        "CV": "Cape Verde",
        "KY": "Cayman Islands",
        "CF": "Central African Republic",
        "TD": "Chad",
        "CL": "Chile",
        "CN": "China",
        "CX": "Christmas Island",
        "CC": "Cocos (Keeling) Islands",
        "CO": "Colombia",
        "KM": "Comoros",
        "CG": "Congo",
        "CD": "Congo",
        "CK": "Cook Islands",
        "CR": "Costa Rica",
        "CI": "Ivory Coast",
        "HR": "Croatia",
        "CU": "Cuba",
        "CY": "Cyprus",
        "CZ": "Czech Republic",
        "DK": "Denmark",
        "DJ": "Djibouti",
        "DM": "Dominica",
        "DO": "Dominican Republic",
        "EC": "Ecuador",
        "EG": "Egypt",
        "SV": "El Salvador",
        "GQ": "Equatorial Guinea",
        "ER": "Eritrea",
        "EE": "Estonia",
        "ET": "Ethiopia",
        "FK": "Falkland Islands (Malvinas)",
        "FO": "Faroe Islands",
        "FJ": "Fiji",
        "FI": "Finland",
        "FR": "France",
        "GF": "French Guiana",
        "PF": "French Polynesia",
        "TF": "French Southern Territories",
        "GA": "Gabon",
        "GM": "Gambia",
        "GE": "Georgia",
        "DE": "Germany",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GR": "Greece",
        "GL": "Greenland",
        "GD": "Grenada",
        "GP": "Guadeloupe",
        "GU": "Guam",
        "GT": "Guatemala",
        "GN": "Guinea",
        "GW": "Guinea-Bissau",
        "GY": "Guyana",
        "HT": "Haiti",
        "HM": "Heard Island and McDonald Islands",
        "VA": "Holy See (Vatican City State)",
        "HN": "Honduras",
        "HK": "Hong Kong",
        "HU": "Hungary",
        "IS": "Iceland",
        "IN": "India",
        "ID": "Indonesia",
        "IR": "Iran",
        "IQ": "Iraq",
        "IE": "Ireland",
        "IL": "Israel",
        "IT": "Italy",
        "JM": "Jamaica",
        "JP": "Japan",
        "JO": "Jordan",
        "KZ": "Kazakhstan",
        "KE": "Kenya",
        "KI": "Kiribati",
        "KP": "North Korea",
        "KR": "South Korea",
        "KW": "Kuwait",
        "KG": "Kyrgyzstan",
        "LA": "Lao People's Democratic Republic",
        "LV": "Latvia",
        "LB": "Lebanon",
        "LS": "Lesotho",
        "LR": "Liberia",
        "LY": "Libya",
        "LI": "Liechtenstein",
        "LT": "Lithuania",
        "LU": "Luxembourg",
        "MO": "Macao",
        "MG": "Madagascar",
        "MW": "Malawi",
        "MY": "Malaysia",
        "MV": "Maldives",
        "ML": "Mali",
        "MT": "Malta",
        "MH": "Marshall Islands",
        "MQ": "Martinique",
        "MR": "Mauritania",
        "MU": "Mauritius",
        "YT": "Mayotte",
        "MX": "Mexico",
        "FM": "Micronesia, Federated States of",
        "MD": "Moldova, Republic of",
        "MC": "Monaco",
        "MN": "Mongolia",
        "MS": "Montserrat",
        "MA": "Morocco",
        "MZ": "Mozambique",
        "MM": "Myanmar",
        "NA": "Namibia",
        "NR": "Nauru",
        "NP": "Nepal",
        "NL": "Netherlands",
        "NC": "New Caledonia",
        "NZ": "New Zealand",
        "NI": "Nicaragua",
        "NE": "Niger",
        "NG": "Nigeria",
        "NU": "Niue",
        "NF": "Norfolk Island",
        "MK": "North Macedonia",
        "MP": "Northern Mariana Islands",
        "NO": "Norway",
        "OM": "Oman",
        "PK": "Pakistan",
        "PW": "Palau",
        "PS": "Palestine",
        "PA": "Panama",
        "PG": "Papua New Guinea",
        "PY": "Paraguay",
        "PE": "Peru",
        "PH": "Philippines",
        "PN": "Pitcairn",
        "PL": "Poland",
        "PT": "Portugal",
        "PR": "Puerto Rico",
        "QA": "Qatar",
        "RE": "Reunion",
        "RO": "Romania",
        "RU": "Russia",
        "RW": "Rwanda",
        "SH": "Saint Helena",
        "KN": "Saint Kitts and Nevis",
        "LC": "Saint Lucia",
        "PM": "Saint Pierre and Miquelon",
        "VC": "Saint Vincent and the Grenadines",
        "WS": "Samoa",
        "SM": "San Marino",
        "ST": "Sao Tome and Principe",
        "SA": "Saudi Arabia",
        "SN": "Senegal",
        "SC": "Seychelles",
        "SL": "Sierra Leone",
        "SG": "Singapore",
        "SK": "Slovakia",
        "SI": "Slovenia",
        "SB": "Solomon Islands",
        "SO": "Somalia",
        "ZA": "South Africa",
        "GS": "South Georgia and the South Sandwich Islands",
        "ES": "Spain",
        "LK": "Sri Lanka",
        "SD": "Sudan",
        "SR": "Suriname",
        "SJ": "Svalbard and Jan Mayen",
        "SZ": "Eswatini",
        "SE": "Sweden",
        "CH": "Switzerland",
        "SY": "Syrian Arab Republic",
        "TW": "Taiwan",
        "TJ": "Tajikistan",
        "TZ": "Tanzania",
        "TH": "Thailand",
        "TL": "Timor-Leste",
        "TG": "Togo",
        "TK": "Tokelau",
        "TO": "Tonga",
        "TT": "Trinidad and Tobago",
        "TN": "Tunisia",
        "TR": "Turkey",
        "TM": "Turkmenistan",
        "TC": "Turks and Caicos Islands",
        "TV": "Tuvalu",
        "UG": "Uganda",
        "UA": "Ukraine",
        "AE": "United Arab Emirates",
        "GB": "United Kingdom",
        "US": "USA",
        "UM": "United States Minor Outlying Islands",
        "UY": "Uruguay",
        "UZ": "Uzbekistan",
        "VU": "Vanuatu",
        "VE": "Venezuela",
        "VN": "Vietnam",
        "VG": "Virgin Islands, British",
        "VI": "Virgin Islands, U.S.",
        "WF": "Wallis and Futuna",
        "EH": "Western Sahara",
        "YE": "Yemen",
        "ZM": "Zambia",
        "ZW": "Zimbabwe",
        "AX": "Aland Islands",
        "BQ": "Bonaire, Sint Eustatius and Saba",
        "CW": "Curaçao",
        "GG": "Guernsey",
        "IM": "Isle of Man",
        "JE": "Jersey",
        "ME": "Montenegro",
        "BL": "Saint Barthélemy",
        "MF": "Saint Martin (French part)",
        "RS": "Serbia",
        "SX": "Sint Maarten (Dutch part)",
        "SS": "South Sudan",
        "XK": "Kosovo"
      },
      FEATURES: 'Features',
      ATTRIBUTES: 'Attributes',
      HIGH_PERFORMANCE: 'High Performance',
      GREEN_ENERGY: 'Green Energy',
      ROCKET_STORAGE: 'Rocket Storage',
      GPU: 'GPU',
      SERVER_PROVISIONING: 'Server provisioning',
      DISTRIBUTED_STORAGE: 'Distributed Storage',
      OBJECT_STORAGE: 'Object Storage',
      OWNER: 'Operator',
      CERTIFICATION_LIST: 'Certification',
      OPERATOR_CERTIFICATION_LIST: 'Operator certification',
      SHARING: 'Sharing',
      SHARING_ENABLED: 'Location shared',
      SHARING_DISABLED: 'Location not shared',
    },
    RESULTS: {
      HEADLINE: 'Locations ({{ numResults }} results)',
      HEADLINE_SINGLE: 'Locations ({{ numResults }} result)',
      RESET_FILTERS: 'Reset filters',
      DETAILS: 'Details',
      OWNER: 'Operator',
      OWNER_WEBSITE: 'Website',
      NO_RESULTS_HEADLINE: 'No locations found',
      NO_RESULTS_TEXT:
        'We are sorry, we could not find a location with the filters set. We are constantly working to unlock new locations for our customers.',
      ENABLE_SHARING: 'Share location with your accounts',
    },
    "HIGH_PERFORMANCE_LOCATION": 'This location is equipped with high-performance hardware.'
  },
  CONTACT_FORM: {
    INTRO: 'We\`re looking forward to your message!',
    INTRO_PRE_EMAIL: 'Write us an e-mail to',
    INTRO_POST_EMAIL: 'or open your default email client now if you configured one.',
    OPEN_EMAIL_CLIENT: 'Open your e-mail client',
    HEADLINE: 'Contact us'
  },
  LOCATION_SELECT: {
    HINT_SWITCHED_OFF:
      'This location has been deactivated in the <a href="/Partner/Accounts/Settings/Locations">global settings</a> for all accounts. If you still select the location for the first project, we activate it accordingly in the account-specific settings of the new account. Thus, the default settings are not used for this account.',
  },
};
