import { Inject, Pipe, PipeTransform, inject } from '@angular/core';
import * as _ from 'lodash';
import { UiTranslateService } from '../lang/ui-translate.service';

@Pipe({
  name: 'uitranslate'
})
export class TranslatePipe implements PipeTransform {
  private readonly translate = inject(UiTranslateService);


  transform(value: string, ...args: unknown[]): string {

    let interpolateParams: object | undefined;
    if (args[0] && args.length) {
      if (typeof args[0] === 'string' && args[0].length) {
        // we accept objects written in the template such as {n:1}, {'n':1}, {n:'v'}
        // which is why we might need to change it to real JSON objects such as {"n":1} or {"n":"v"}
        const validArgs: string = args[0]
          .replace(/(\')?([a-zA-Z0-9_]+)(\')?(\s)?:/g, '"$2":')
          .replace(/:(\s)?(\')(.*?)(\')/g, ':"$3"');
        try {
          interpolateParams = JSON.parse(validArgs);
        } catch (e) {
          throw new SyntaxError(`Wrong parameter in TranslatePipe. Expected a valid Object, received: ${args[0]}`);
        }
      } else if (typeof args[0] === 'object' && !Array.isArray(args[0])) {
        interpolateParams = args[0];
      }
    }

    if (value) {
      return this.translate.instant(value, interpolateParams);
    }
    return "";
  }

}
