import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent, FooterComponent, IngridTemplateDirective } from './shared.components';
import { TranslatePipe } from './pipes/translate.pipe';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { UpperfirstPipe } from './pipes/upperfirst.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { StripHtmlPipe } from './pipes/striphtml.pipe';
import { CurrencyTemplatePipe } from './pipes/currency-template.pipe';


@NgModule({
  imports: [
    CommonModule
  ],
  exports: [HeaderComponent, FooterComponent, IngridTemplateDirective, TranslatePipe, UpperfirstPipe, CapitalizePipe, MarkdownPipe, StripHtmlPipe, CurrencyTemplatePipe],
  declarations: [HeaderComponent, FooterComponent, IngridTemplateDirective, TranslatePipe, UpperfirstPipe, CapitalizePipe, MarkdownPipe, StripHtmlPipe, CurrencyTemplatePipe],
  providers: [
    MarkdownPipe,
    StripHtmlPipe
  ]
})
export class SharedModule { }
