export const de = {
  no: 'Nein',
  yes: 'Ja',
  cancel: 'Abbrechen',
  generell_clipboard_copy: 'Zum Kopieren klicken',
  generell_clipboard_copy_done: 'Kopiert',
  close: 'Schliessen',
  statuspage: 'Statusseite',
  status_pending: 'Ausstehend',
  now: 'Jetzt',
  notification_center_placeholder: 'Im Moment keine Benachrichtigungen.',
  notification_center_release_notes_label: 'Release notes',
  notification_center_release_notes_subscribe: 'Abonnieren',
  maintenance_incidents: 'Wartungsarbeiten & Zwischenfälle',
  incident_scheduled: 'geplant',
  incident_investigating: 'wird untersucht',
  incident_identified: 'in Arbeit',
  incident_watching: 'wird beobachtet',
  goto_statuspage: 'Zur Statusseite',
  release_notes_all: 'Alle Release Notes auf unserer Webseite',
  send: 'Senden',
  januar: 'Januar',
  january: 'Januar',
  februar: 'Februar',
  february: 'Februar',
  märz: 'März',
  march: 'März',
  april: 'April',
  mai: 'Mai',
  may: 'Mai',
  juni: 'Juni',
  june: 'Juni',
  juli: 'Juli',
  july: 'Juli',
  august: 'August',
  september: 'September',
  oktober: 'Oktober',
  october: 'Oktober',
  november: 'November',
  dezember: 'Dezember',
  december: 'Dezember',
  usage_limit_warning_headline: 'Account Limit Warnung',
  usage_limit_warning_headline_overview: 'Account Limits Übersicht',
  usage_limit_warning_info_pre: 'Du kannst deine Account Limits ',
  usage_limit_warning_info_link: 'hier',
  usage_limit_warning_info_post:
    ' einsehen. Kontaktiere uns wenn du mehr benötigst.',
  platformstatus: 'Plattform-Status',
  platformstatus_online: 'Plattform ist verfügbar',
  platformstatus_performance_issues: 'Performance-Probleme',
  platformstatus_performance_issues_long:
    'Derzeit treten Performance-Probleme auf',
  platformstatus_partial_outage: 'Teilausfall',
  platformstatus_partial_outage_long: 'Teile der Plattform sind ausgefallen',
  platformstatus_major_outage: 'großer Ausfall',
  platformstatus_major_outage_long:
    'Große Teile der Plattform sind ausgefallen',
  limit_capacity_total: 'Speicherplatz',
  limit_capacity_total_local: 'Rocket Storage Speicherplatz',
  limit_cores_total: 'Cores',
  limit_ipv4_total: 'IPv4',
  limit_ipv6_total: 'IPv6',
  limit_loadbalancers_total: 'Load Balancers',
  limit_memory_total: 'Arbeitsspeicher',
  limit_object_storage_buckets_total: 'Object Storage Buckets',
  limit_object_storage_user_quota: 'Object Storage Speicherplatz',
  limit_paas_security_zones_total: 'Security Zones',
  limit_snapshots_total: 'Snapshots',
  limit_snapshots_total_local: 'Snapshots Rocket Storage',
  limit_autoscale_info:
    'Wir skalieren deine Limits automatisch um die beste Balance aus Sicherheit und Skalierbarkeit zu garantieren.',
  set_default: 'Standard Tabellen Layout wiederherstellen',
  uebernehmen: 'Übernehmen',
  none: 'Keine',
  table_settings_headline: 'Layout deiner Liste',
  table_settings_intro:
    'Hier kannst du dein persönliches Layout für diese Tabelle anpassen. Die Änderungen werden sofort in deinem Account übernommen.',
  sort_asc: 'Aufsteigend sortieren',
  sort_desc: 'Absteigend sortieren',
  delete_confirm_headline:
    '{{ objectType }} "{{ objectName }}" dauerhaft löschen?',
  remove_confirm_headline:
    '{{ objectType }} "{{ objectName }}" wirklich entfernen?',
  delete_confirm_keep: 'Behalten',
  remove_confirm_keep: 'Behalten',
  delete_confirm_delete: '{{ objectType }} löschen',
  remove_confirm_delete: '{{ objectType }} entfernen',
  delete_confirm_type_to_confirm_label_pre: 'Bitte schreibe',
  delete_confirm_type_to_confirm_label_post: 'um fortzufahren',
  delete_confirm_critical_warning:
    'Diese Aktion kann nicht rückgängig gemacht werden.',
  delete_confirm_selected: 'Ausgewählt',
  delete_confirm_objects: 'Objekte',
  delete_confirm_objects_singular: 'Objekt',
  delete_confirm_deselect_all: 'Alle abwählen',
  delete_confirm_select_all: 'Alle auswählen',
  copy_uuid: 'UUID kopieren',
  form_required_hint: '* Pflichtfeld',

  HELP_CENTER_HEADLINE: 'Wie können wir dir helfen?',
  HELP_CENTER_SUBHEADLINE:
    'Nutze eine unserer digitalen Hilfsmöglichkeiten, um Hilfe für dein Problem zu finden.',
  HELP_CENTER_SEARCHHEADLINE: 'Schau dich in unseren Tutorials um',
  HELP_CENTER_PLACEHOLDER: 'Suche nach Themen oder Produkten',
  HELP_CENTER_EMPTY: 'Wir konnten leider kein passendens Tutorial finden',
  HELP_CENTER_BOX_1_HEADLINE: 'Platform Status',
  HELP_CENTER_BOX_1_TEXT:
    'Prüfe den aktuellen Status der Panels auf unserer Statusseite.',
  HELP_CENTER_BOX_1_LINK: 'Statusseite',
  HELP_CENTER_BOX_1_TARGET: 'https://status.gridscale.io/',
  HELP_CENTER_BOX_2_HEADLINE: 'Produkt Dokumentation',
  HELP_CENTER_BOX_2_TEXT:
    'Besuche unsere umfassende und einfache Beschreibung unserer digitalen Produkte.',
  HELP_CENTER_BOX_2_LINK: 'Produkt Dokumentation',
  HELP_CENTER_BOX_2_TARGET:
    'https://my.gridscale.io/product-documentation/',
  HELP_CENTER_BOX_3_HEADLINE: 'Frequently asked questions',
  HELP_CENTER_BOX_3_TEXT:
    'Schau dir unsere Antworten auf die meistgestellten Fragen an.',
  HELP_CENTER_BOX_3_LINK: 'FAQ',
  HELP_CENTER_BOX_3_TARGET: 'https://gridscale.io/community/faq/',
  HELP_CENTER_SUPPORT_HEADLINE: 'Support in 3 einfachen Schritten',
  HELP_CENTER_SUPPORT_SUBHEADLINE:
    'Du hast ein Problem mit unserem Panel, worauf du einfach keine Antwort findest? Dann frag den Support an und wir versuchen dir schnellstmöglich weiterzuhelfen.',
  HELP_CENTER_STEP_1_1: 'Schreibe uns von deinem Problem',
  HELP_CENTER_STEP_1_2:
    ' Sei dabei gerne so genau, wie möglich, damit wir dir schnellstmöglich helfen können. Gerne kannst du durch Screenshots etc. dein Problem genauer beschreiben.',
  HELP_CENTER_STEP_2_1: 'Verifiziere dein Support-Ticket',
  HELP_CENTER_STEP_2_1_2: '(und lass es bei Bedarf eskalieren)',
  HELP_CENTER_STEP_2_2:
    'Wir schicken dir eine Mail mit Bitte um Bestätigung, um sicher zu gehen, dass wir echte Kundenprobleme lösen. Wenn es drängt kannst du das Ticket auch eskalieren lassen.',
  HELP_CENTER_STEP_3_1: 'Erhalte umgehend Hilfe von uns!',
  HELP_CENTER_STEP_3_2:
    'Wir versuchen dir schnellstmöglich weiterzuhelfen und treten mit dir per Mail oder Anruf (sofern du uns darum geben hast) in Kontakt. Gemeinsam schaffen wir es!',
  HELP_CENTER_CTA: 'Support Anfragen',
  HELP_CENTER_EMAIL: 'support@gridscale.io',
  HELP_CENTER_EMAIL_PARTNER: 'partner@gridscale.io',
  HELP_CENTER_LOADING: 'Wir suchen nach passenden Tutorials für dich ...',
  HELP_CENTER_SHOW_ALL: 'Zu allen Tutorials',
  RESET_TO_DEFAULT: 'Standardwert wiederherstellen',

  got_it: 'Alles klar!',
  hotspot_deactivate: 'Keine Tipps mehr anzeigen',
  hotspot_family_deactivate: 'Keine Tipps mehr anzeigen',

  nps_label_low: 'Sehr unwahrscheinlich',
  nps_label_high: 'Sehr wahrscheinlich',
  nps_question:
    'Wie wahrscheinlich ist es, dass Du unser Produkt weiterempfiehlst?',
  nps_comment_question_10:
    'Wir lieben positives Feedback, warum bewertest du uns so hoch?',
  nps_comment_question_not_10:
    'Was könnten wir tun, um deine Stimme um nur einen Punkt zu erhöhen?',
  nps_thanks_header: 'Danke für dein Feedback!',
  nps_thanks_body:
    'Wir arbeiten stetig daran unser Produkt zu verbessern, dafür warst du uns eine große Hilfe. Danke.',

  location_explorer_headline: 'Location Explorer',
  location_explorer_locations: 'Standorte',
  location_explorer_show_all: 'Alle anzeigen',
  location_explorer_show_less: 'Weniger anzeigen',
  location_explorer_allow_usage: 'Standort für Konten freigeben',
  location_explorer_intro:
    'Hier kannst du alle Standorte sehen, in denen du Ressourcen erstellen kannst. Nutze die Filter oder die Karte um mehr über die jeweiligen Standorte zu erfahren.',
  location_explorer_intro_p:
    'Hole dir die Standortinformationen, die du brauchst und entscheide, welche Standorte du für deine Konten frei gibst.',
  location_explorer_filter_title: 'Filter',
  location_explorer_filter_enabled: 'Freigabe',
  location_explorer_filter_enabled_enabled: 'Freigegebene Location',
  location_explorer_filter_enabled_disabled: 'Nicht freigegebene Location',
  location_explorer_filter_country: 'Standort',
  location_explorer_filter_country_de: 'Deutschland',
  location_explorer_filter_country_fr: 'Frankreich',
  location_explorer_filter_country_ch: 'Schweiz',
  location_explorer_filter_country_at: 'Österreich',
  location_explorer_filter_country_nl: 'Niederlande',
  location_explorer_filter_features: 'Funktionen',
  location_explorer_filter_features_has_rocket_storage: 'Rocket Storage',
  location_explorer_filter_features_has_server_provisioning:
    'Server Provisioning',
  location_explorer_filter_features_object_storage_region: 'Object Storage',
  location_explorer_filter_features_has_gpu: 'GPU',
  location_explorer_filter_partner_certification: 'Zertifizierung',
  location_explorer_filter_operator: 'Betreiber',
  location_explorer_filter_operator_certification_list:
    'Betreiber Zertifizierung',
  location_explorer_filter_reset: 'Filter zurücksetzen',
  location_explorer_details: 'Details',
  location_explorer_owner: 'Betreiber',
  location_explorer_website: 'Webseite',
  location_explorer_empty_headline: 'Keine Locations gefunden',
  location_explorer_empty_text:
    'Es tut uns leid, wir konnten keine Location mit den gesetzen Filtern finden. Wir arbeiten stetig daran neue Locations für unsere Kunden freizuschalten. In der Zwischenzeit kannst du dein Glück noch einmal mit anderen Filtern versuchen.',
  location_explorer_leaflet_gesturehandling_touch:
    'Benutze zwei Finger zum Zoomen der Karte',
  location_explorer_leaflet_gesturehandling_scroll:
    'Verwende Strg + Scrollen zum Zoomen der Karte',
  location_explorer_leaflet_gesturehandling_scrollMac:
    'Verwende \u2318 + Scrollen zum Zoomen der Karte',
  PASSWORD_PROMPT: 'Vergebe ein sicheres Passwort',
  PASSWORD_STRENGTH: 'Stärke',
  PASSWORD_STRENGTH_WEAK: 'Gruselig 😨',
  PASSWORD_STRENGTH_MEDIUM: 'Ausreichend 😐',
  PASSWORD_STRENGTH_STRONG: 'Stark 😎',
  BACKGROUND_JOBS: 'Hintergrundaufgaben',
  BACKGROUND_JOBS_REMOVE_FINISHED: 'Abgeschlossene Aufgaben entfernen',
  BACKGROUND_JOBS_REMOVE_ALL: 'Alle Aufgaben verbergen',
  MINIMIZE: 'Minimieren',
  MAXIMIZE: 'Maximieren',
  PRODUCT: 'Produkt',
  PRICE: 'Preis',
  PRICE_FOR_LOCATION: 'Preis für Standort {{ locationName }}',

  PRODUCT_DOC: 'Produkt Dokumentation',
  API_DOC: 'API Dokumentation',
  FURTHER_INFORMATION: 'Weiterführende Informationen findest du hier:',

  DETAILS: {
    NAVIGATE_TO: 'Wechsle zu {{ objectType }} "{{ name }}"',
    NAVIGATE_PREV: 'Zum vorherigen Objekt wechseln',
    NAVIGATE_NEXT: 'Zum nächsten Objekt wechseln',
    NO_ACTIONS_WHILE_LOADING:
      'Während der Bereitstellung können keine Aktionen durchgeführt werden.',
  },
  PRICEBOX: {
    DEPRECATED_TOOLTIP: 'Discontinued: Wir bieten für dieses Produkt keine weiteren Updates an. Unsere Empfehlung ist daher, auf eine aktuellere Version umzusteigen. Bis dahin erfolgt die Abrechnung zum bekannten  Preis. Mehr erfährst du in unserer [Produkt Dokumentation](/docs).',
    PRICE_STATIC: 'Preis pro',
    PRICE_NEW_STATIC: 'Neuer Preis pro',
    DAY: 'Tag',
    MONTH: 'Monat',
    HOUR: 'Stunde',
    UPDATE: 'Update',
    ACTUAL: 'Aktuell',
    REALTIMESCALING_MINIMUM: 'Nutzungsbasiert, mind.',
    REALTIMESCALING: 'Nutzungsbasiert',
    CHANGES: 'Anpassungen',
    REVERT: 'Änderungen verwerfen',
    CONFIGURATION: 'Konfiguration',
    PRODUCT: 'Produkt',
    AMOUNT: 'Menge',
    CHOICE: 'Auswahl',
    SUM_PER_DAY: 'Summe / Tag',
    SUM_PER: 'Summe /',
    MEMORY: 'Arbeitsspeicher',
    CORE: 'Kerne',
    STORAGE: 'Distributed Storage Standard I/O',
    STORAGE_HIGH: 'Distributed Storage High I/O',
    STORAGE_INSANE: 'Distributed Storage Insane I/O',
    STORAGE_LOCAL: 'Rocket Storage',
    LABELS: 'Labels',
    INCLUSIVE: 'inklusive',
    CHANGES_WITHOUT_PRICE_EFFECT: 'Änderungen ohne Preisauswirkungen',
    PRICE_PER_DAY: 'Preis pro Tag',
    PRICE_PER_MONTH: 'Preis pro Monat',
    PRICE_NEW_PER_DAY: 'Neuer Preis pro Tag',
    PRICE_NEW_PER_MONTH: 'Neuer Preis pro Monat',
    CREATE: 'Erstellen',
    PAAS_CORE: 'CPU Kern',
    PAAS_STORAGE: 'Storage',
    PAAS_REALTIME_STORAGE_STANDARD:
      'Realtime Scaling Storage Standard Performance',
    PAAS_REALTIME_STORAGE_HIGH: 'Realtime Scaling Storage High Performance',
    PAAS_REALTIME_STORAGE_INSANE: 'Realtime Scaling Storage Insane Performance',
    BASE_FEE: 'Grundgebühr',
    MINIMUM_CONFIGURATION: 'Mindestkonfiguration',
    SUBTOTAL: 'Zwischensumme',
    TOOLTIP_DISCOUNTED:
      'Rabattierter Preis bis zum {{ discountEndDate }} - danach {{ regularPrice }}',
    TOOLTIP_DISCOUNTED_WITH_CHANGES:
      'Rabattierter Preis bis zum {{ discountEndDate }} - danach {{ regularPrice }} bzw. {{ regularPriceWithChanges }} nach deinen Änderungen',
    ISOIMAGE: 'ISO-Image',
    READY_TO_CREATE: 'Bereit zum Erstellen',
    READY_TO_CREATE_TOOLTIP:
      'Dieses {{ objectName }} wird zusammen mit dem {{ objectNameParent }} erstellt.',
    ALREADY_CREATED: 'Bereits erstellt',
    ALREADY_CREATED_TOOLTIP:
      'Dieses Objekt wurde im Zuge des Erstellungsprozesses bereits erstellt und ist aktiv. Solltest du den {{ objectNameParent }} nicht benötigen, musst du es von Hand löschen.',
    AT_LEAST_ABBR: 'mind.',
    COST_MODEL_USAGE_INFO:
      'Dieses Produkt wird “usage based” abgerechnet. Damit zahlst du nur für das, was du tatsächlich verbrauchst – minutengenau.',
    COST_MODEL_REALTIME_INFO:
      'Dieses Produkt nutzt Realtime Scaling, wodurch die Hardwarenutzung automatisch an die Ansprüche deiner Anwendung angepasst wird. Dementsprechend skalieren auch die Kosten entsprechend der benötigten Ressourcen. Wir zeigen dir die Preise für einen maximalen Bedarf an.',
    BILLING_TYPE_TEXT_REALTIME_SCALING: 'mit Realtime Scaling',
    BILLING_TYPE_TOOLTIP_REALTIME_SCALING:
      'Durch Realtime Scaling starten deine Services mit der Mindestkonfiguration und erhöhen sich bei Bedarf automatisch. Wir zeigen dir den Mindest- und Höchstpreis.',
    VARIABLE_COSTS: 'Variable Kosten (nach Bedarf)',
    AUTOSCALING_PRODUCT_LABELS: {
      paas_core: 'CPU Cores',
      paas_storage: 'Storage',
      paas_realtime_storage_standard:
        'Realtime Scaling Storage Standard Performance',
      paas_realtime_storage_high: 'Realtime Scaling Storage High Performance',
      paas_realtime_storage_insane:
        'Realtime Scaling Storage Insane Performance',
    },
    AUTOSCALING_AMOUNT_SUFFIXES: {
      paas_core: 'Cores',
      paas_storage: 'GB',
      paas_realtime_storage_standard: 'GB',
      paas_realtime_storage_high: 'GB',
      paas_realtime_storage_insane: 'GB',
    },
  },
  PRICELIST: {
    COMPARE_LOCATION: 'Vergleich mit Standort',
    FREE: 'Kostenlos',
    PRODUCT_NOT_AVAILABLE: 'Produkt an diesem Standort nicht verfügbar',
    DISCONTINUED_TOOLTIP:
      'Dieses Produkt ist "discontinued", was bedeutet, dass bereits existierende Cloud Ressourcen weiterhin bestehen, jedoch keine neuen erstellt werden können.<br /><a href="/product-documentation/cloud-computing/enterprise-cloud/servicelevelagreement/#discontinued" target="_blank">Mehr erfahren<i class=\'icon-a106_linkextern\'></i></a>',
    EXPAND_ALL_SECTIONS: 'Alle Abschnitte ausklappen',
    COLLAPSE_ALL_SECTIONS: 'Alle Abschnitte einklappen',
    GROUP_LABELS: {
      CPU_RAM: 'CPU & RAM',
      GPU: 'GPU',
      SERVER: 'Server',
      STORAGE: 'Storage',
      DISTRIBUTED_STORAGE: 'Distributed Storage',
      ROCKET_STORAGE: 'Rocket Storage',
      TEMPLATES_WINDOWS: 'Templates: Windows',
      STORAGE_FUNCTIONS: 'Storage Funktionen',
      OBJECT_STORAGE: 'Object Storage',
      NETWORK: 'Netzwerk',
      LOADBALANCER: 'Load Balancer',
      IP_ADRESSES: 'IP-Adressen',
      PAAS: 'PaaS',
      PAAS_INFO:
        'Die meisten unserer Platform Services funktionieren mit Realtime Scaling. Das bedeutet, dass die Services automatisch skalieren und (neben der Grundgebühr und den Kosten der Mindestkonfiguration) nur die Kosten für die genutzten Ressourcen anfallen.  Die Grundgebühr für deine Services konfigurierst du im entsprechenden Service-Abschnitt, die Mindestkonfiguration setzt sich je Service anders aus CPU und Storage zusammensetzen – diese Preise bestimmst du im Abschnitt “Realtime Scaling”.',
      PAAS_INFO_COMPARE_MODE: 'Die meisten unserer Platform Services funktionieren mit Realtime Scaling. Das bedeutet, dass die Services automatisch skalieren und (neben der Grundgebühr und den Kosten der Mindestkonfiguration) nur die Kosten für die genutzten Ressourcen anfallen.',
      REALTIME_SCALING: 'Realtime Scaling',
      REALTIME_SCALING_INFO:
        'Für unsere Realtime Scaling-Dienste berechnen wir eine Mindestkonfiguration, die du an den entsprechenden Services einsehen kannst. Deine Realtime Scaling Preise werden sowohl für die Mindestkonfigurationen als auch die eigentliche Nutzung der Ressourcen verwendet.',
      GRIDSQL: 'gridSQL',
      GRIDSQL_INFO:
        'All unsere gridSQL-Services beinhalten Realtime Scaling, wobei sich die Mindestkonfigurationen unterscheiden: für SQL Server gilt die Mindestkonfiguration von 1 GB Storage, während für MariaDB, MySQL und PostgreSQL je 1 Core und 1 GB berechnet werden. Außerdem kannst du für SQL Server auf die ersten 30 Tage rabattierte Preise festlegen.',
      GRIDSTORE: 'gridStore',
      GRIDSTORE_INFO:
        'Die Preise für unsere gridStore-Services werden unterschiedlich berechnet: Während Memcached mit Realtime Scaling ausgestattet sind kannst du für Redis Cache und Redis Store Festpreise setzen.',
      GRIDFS: 'gridFs',
      GRIDFS_INFO:
        'Für gridFs berechnen wir keine Mindestkonfiguration und Realtime Scaling ist nicht nötig – deine Kunden zahlen pro GB Speicherplatz.',
      GRIDFS_INFO_TOOLTIP:
        'Der Preis für das  erste GB wird bereits ab 0,000001 GB Speicherplatz fällig.',
      VIRTUAL_LOCATION: 'Virtual Location',
      DEDICATED_NODES: 'Dedicated Nodes',
      OTHER: 'Andere',
    },
    PAAS_FLAVOR_HEADLINES: {
      postgres: 'Flavor: PostgreSQL',
      mysql: 'Flavor: MySQL',
      mariadb: 'Flavor: MariaDB',
      mssql_discounted: 'Flavor: SQL Server (*für die ersten 30 Tage)',
      mssql_discounted_TOOLTIP:
        'Wir bieten unseren Kunden die ersten 30 Tage 50 % Rabatt auf ihre SQL Server an. Du kannst selbiges tun oder den Preis deiner Wahl eintragen. Die ersten 30 Tage werden mit den Preisen in diesem Abschnitt abgerechnet.',
      mssql: 'Flavor: SQL Server',
    },
    PER: 'pro',
    MONTH: 'Monat',
    LICENCE: 'Lizenz',
    PROVISIONAL_TOTAL: 'Vorläufiger Gesamtpreis',
    PROVISIONAL_TOTAL_INTRO1:
      'Der vorläufige Gesamtpreis  setzt sich wie folgt zusammen:',
    PROVISIONAL_TOTAL_BASEFEE: 'Grundgebühr',
    PROVISIONAL_TOTAL_OUTRO:
      'Durch Realtime Scaling kommen später nur die Kosten für die  genutzten Ressourcen hinzu.',
    PRIVATE_TEMPLATE: 'Privates Template',
    accounts_settings_prices_location: 'Preise für folgenden Standort anzeigen:',
    prices_reset_field: 'Standard aus Preisliste wiederherstellen',
  },
  price_discount_calculator_intro:
    'Nutze den Rabattrechner, um allen Preisen auf einmal einen Rabatt in % zu geben',
  price_discount_calculator: 'Rabattrechner',
  price_template_save_discount: 'Vorlage speichern',
  partner_price: 'Einkaufspreis',
  account_price: 'Verkaufspreis',
  GENERAL: {
    MORE: 'Mehr',
    HOTSPOT_PRICE_BOX:
      'Wir haben für dich mehr Platz in den Erstellen-Dialogen geschaffen und die Preisbox neu platziert. Öffne sie, um den neuen Aufbau zu sehen.',
    PER: 'pro',
    DISABLED_ROLE_BELOW_WRITE:
      'Du verfügst nicht über eine Schreib-Berechtigung.',
    FIND_OUT_MORE: 'Mehr erfahren',
    PAGE_NOT_FOUND: 'Seite nicht gefunden',
    PAGE_NOT_FOUND_TEXT:
      'Es tut uns leid! Wir haben überall gesucht, die Seite ist einfach nicht aufzufinden.',
    GO_BACK: 'Zurück',
  },
  missing_rights_to_edit: 'Dir fehlt die Berechtigung zum Bearbeiten',
  ZERO_OR_MANY_RESULT: '{{ number }} Ergebnisse',
  ONE_RESULTS: '{{ number }} Ergebnis',

  TABLE_GEN_3: {
    SETTINGS_MODAL: {
      HEADLINE: 'Tabelle anpassen',
      INTRO:
        'Hier kannst du dein persönliches Layout für diese Tabelle anpassen. Die Änderungen werden sofort gespeichert.',
      RESET: 'Standard Tabellen Layout wiederherstellen',
      CONFIRM: 'Übernehmen',
      NONE: 'Keine',
    },
    SORT: {
      ASC: 'Aufsteigend sortieren',
      DESC: 'Absteigend sortieren',
    },
    FAVORITES: {
      LABEL: 'Favoriten',
      MAKE: 'Favorisieren',
      SELECTED: 'ausgewählt',
      SELECTED_SINGLE: 'ausgewählt',
      CLOSE: 'Abbrechen',
    },
    FILTER: {
      PLACEHOLDER: 'Filtern',
      EMPTY_HEADLINE: 'Keine {{searchables}} gefunden',
      EMPTY: 'Überprüfe deine Eingabe oder beginne eine neue Suche',
    },
  },

  DISRUPTORS: {
    BUTTON_NEXT: 'Weiter',
    BUTTON_UNDERSTOOD: 'Verstanden',
  },

  PRODUCT_LABEL: {
    NEW: 'Neu',
    DEPRECATED: 'Deprecated',
    DISCONTINUED: 'Discontinued',
    RECOMMENDED: 'Empfohlen',
    LABS: 'Labs',
    UPGRADE: 'Upgrade',
    FREE: 'Gratis',
    REALTIME_SCALING: 'Realtime Scaling!'
  },

  LOCATION_EXPLORER_2: {
    HEADLINE: 'Location Explorer',
    INTRO_PARTNER:
      'Hole dir die Standortinformationen, die du brauchst und entscheide, welche Standorte du für deine Konten frei gibst.',
    INTRO_PUBLIC:
      'Hier kannst du alle Standorte sehen, in denen du Ressourcen erstellen kannst. Nutze die Filter oder die Karte um mehr über die jeweiligen Standorte zu erfahren.',
    GESTURE_HANDLING_TOUCH: 'Benutze zwei Finger zum Zoomen der Karte',
    GESTURE_HANDLING_SCROLL: 'Verwende Strg + Scrollen zum Zoomen der Karte',
    GESTURE_HANDLING_SCROLL_MAC:
      'Verwende \u2318 + Scrollen zum Zoomen der Karte',
    FILTER: {
      HEADLINE: 'Filter',
      SHOW_MORE: 'Alle anzeigen',
      SHOW_LESS: 'Weniger anzeigen',
      COUNTRY: 'Land',
      COUNTRIES: {
        LABEL: 'Land',
        "AF": "Afghanistan",
        "EG": "Ägypten",
        "AX": "Åland",
        "AL": "Albanien",
        "DZ": "Algerien",
        "AS": "Amerikanisch-Samoa",
        "VI": "Amerikanische Jungferninseln",
        "AD": "Andorra",
        "AO": "Angola",
        "AI": "Anguilla",
        "AQ": "Antarktis",
        "AG": "Antigua und Barbuda",
        "GQ": "Äquatorialguinea",
        "AR": "Argentinien",
        "AM": "Armenien",
        "AW": "Aruba",
        "AZ": "Aserbaidschan",
        "ET": "Äthiopien",
        "AU": "Australien",
        "BS": "Bahamas",
        "BH": "Bahrain",
        "BD": "Bangladesch",
        "BB": "Barbados",
        "BY": "Belarus",
        "BE": "Belgien",
        "BZ": "Belize",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BT": "Bhutan",
        "BO": "Bolivien",
        "BQ": "Bonaire",
        "BA": "Bosnien und Herzegowina",
        "BW": "Botswana",
        "BV": "Bouvetinsel",
        "BR": "Brasilien",
        "VG": "Britische Jungferninseln",
        "IO": "Britisches Territorium im Indischen Ozean",
        "BN": "Brunei Darussalam",
        "BG": "Bulgarien",
        "BF": "Burkina Faso",
        "BI": "Burundi",
        "CL": "Chile",
        "CN": "China",
        "CK": "Cookinseln",
        "CR": "Costa Rica",
        "CI": "Elfenbeinküste",
        "CW": "Curaçao",
        "DK": "Dänemark",
        "DE": "Deutschland",
        "DM": "Dominica",
        "DO": "Dominikanische Republik",
        "DJ": "Dschibuti",
        "EC": "Ecuador",
        "SV": "El Salvador",
        "ER": "Eritrea",
        "EE": "Estland",
        "FK": "Falklandinseln",
        "FO": "Färöer",
        "FJ": "Fidschi",
        "FI": "Finnland",
        "FR": "Frankreich",
        "GF": "Französisch-Guayana",
        "PF": "Französisch-Polynesien",
        "TF": "Französische Süd- und Antarktisgebiete",
        "GA": "Gabun",
        "GM": "Gambia",
        "GE": "Georgien",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GD": "Grenada",
        "GR": "Griechenland",
        "GL": "Grönland",
        "GP": "Guadeloupe",
        "GU": "Guam",
        "GT": "Guatemala",
        "GG": "Guernsey",
        "GN": "Guinea",
        "GW": "Guinea-Bissau",
        "GY": "Guyana",
        "HT": "Haiti",
        "HM": "Heard und McDonaldinseln",
        "HN": "Honduras",
        "HK": "Hong Kong",
        "IN": "Indien",
        "ID": "Indonesien",
        "IM": "Insel Man",
        "IQ": "Irak",
        "IR": "Iran",
        "IE": "Irland",
        "IS": "Island",
        "IL": "Israel",
        "IT": "Italien",
        "JM": "Jamaika",
        "JP": "Japan",
        "YE": "Jemen",
        "JE": "Jersey",
        "JO": "Jordanien",
        "KY": "Kaimaninseln",
        "KH": "Kambodscha",
        "CM": "Kamerun",
        "CA": "Kanada",
        "CV": "Kap Verde",
        "KZ": "Kasachstan",
        "QA": "Katar",
        "KE": "Kenia",
        "KG": "Kirgisistan",
        "KI": "Kiribati",
        "CC": "Kokosinseln",
        "CO": "Kolumbien",
        "KM": "Komoren",
        "CD": "Kongo",
        "KP": "Nordkorea",
        "KR": "Südkorea",
        "HR": "Kroatien",
        "CU": "Kuba",
        "KW": "Kuwait",
        "LA": "Laos",
        "LS": "Lesotho",
        "LV": "Lettland",
        "LB": "Libanon",
        "LR": "Liberia",
        "LY": "Libyen",
        "LI": "Liechtenstein",
        "LT": "Litauen",
        "LU": "Luxemburg",
        "MO": "Macao",
        "MG": "Madagaskar",
        "MW": "Malawi",
        "MY": "Malaysia",
        "MV": "Malediven",
        "ML": "Mali",
        "MT": "Malta",
        "MA": "Marokko",
        "MH": "Marshallinseln",
        "MQ": "Martinique",
        "MR": "Mauretanien",
        "MU": "Mauritius",
        "YT": "Mayotte",
        "MX": "Mexiko",
        "FM": "Mikronesien",
        "MD": "Moldawien",
        "MC": "Monaco",
        "MN": "Mongolei",
        "ME": "Montenegro",
        "MS": "Montserrat",
        "MZ": "Mosambik",
        "MM": "Myanmar",
        "NA": "Namibia",
        "NR": "Nauru",
        "NP": "Nepal",
        "NC": "Neukaledonien",
        "NZ": "Neuseeland",
        "NI": "Nicaragua",
        "NL": "Niederlande",
        "NE": "Niger",
        "NG": "Nigeria",
        "NU": "Niue",
        "MK": "Nordmazedonien",
        "MP": "Nördliche Marianen",
        "NF": "Norfolkinsel",
        "NO": "Norwegen",
        "OM": "Oman",
        "AT": "Österreich",
        "TL": "Osttimor",
        "PK": "Pakistan",
        "PS": "Staat Palästina",
        "PW": "Palau",
        "PA": "Panama",
        "PG": "Papua-Neuguinea",
        "PY": "Paraguay",
        "PE": "Peru",
        "PH": "Philippinen",
        "PN": "Pitcairninseln",
        "PL": "Polen",
        "PT": "Portugal",
        "PR": "Puerto Rico",
        "TW": "Taiwan",
        "CG": "Republik Kongo",
        "RE": "Réunion",
        "RW": "Ruanda",
        "RO": "Rumänien",
        "RU": "Russland",
        "BL": "Saint-Barthélemy",
        "MF": "Saint-Martin",
        "SB": "Salomonen",
        "ZM": "Sambia",
        "WS": "Samoa",
        "SM": "San Marino",
        "ST": "São Tomé und Príncipe",
        "SA": "Saudi-Arabien",
        "SE": "Schweden",
        "CH": "Schweiz",
        "SN": "Senegal",
        "RS": "Serbien",
        "SC": "Seychellen",
        "SL": "Sierra Leone",
        "ZW": "Simbabwe",
        "SG": "Singapur",
        "SX": "Sint Maarten",
        "SK": "Slowakei",
        "SI": "Slowenien",
        "SO": "Somalia",
        "ES": "Spanien",
        "LK": "Sri Lanka",
        "SH": "St. Helena",
        "KN": "St. Kitts und Nevis",
        "LC": "St. Lucia",
        "PM": "Saint-Pierre und Miquelon",
        "VC": "St. Vincent und die Grenadinen",
        "ZA": "Südafrika",
        "SD": "Sudan",
        "GS": "Südgeorgien und die Südlichen Sandwichinseln",
        "SS": "Südsudan",
        "SR": "Suriname",
        "SJ": "Svalbard und Jan Mayen",
        "SZ": "Eswatini",
        "SY": "Syrien, Arabische Republik",
        "TJ": "Tadschikistan",
        "TZ": "Tansania",
        "TH": "Thailand",
        "TG": "Togo",
        "TK": "Tokelau",
        "TO": "Tonga",
        "TT": "Trinidad und Tobago",
        "TD": "Tschad",
        "CZ": "Tschechien",
        "TN": "Tunesien",
        "TR": "Türkei",
        "TM": "Turkmenistan",
        "TC": "Turks- und Caicosinseln",
        "TV": "Tuvalu",
        "UG": "Uganda",
        "UA": "Ukraine",
        "HU": "Ungarn",
        "UM": "United States Minor Outlying Islands",
        "UY": "Uruguay",
        "UZ": "Usbekistan",
        "VU": "Vanuatu",
        "VA": "Vatikanstadt",
        "VE": "Venezuela",
        "AE": "Vereinigte Arabische Emirate",
        "US": "USA",
        "GB": "Großbritannien",
        "VN": "Vietnam",
        "WF": "Wallis und Futuna",
        "CX": "Weihnachtsinsel",
        "EH": "Westsahara",
        "CF": "Zentralafrikanische Republik",
        "CY": "Zypern",
        "XK": "Kosovo"
      },
      FEATURES: 'Features',
      ATTRIBUTES: 'Attribute',
      HIGH_PERFORMANCE: 'High Performance',
      GREEN_ENERGY: 'Green Energy',
      ROCKET_STORAGE: 'Rocket Storage',
      GPU: 'GPU',
      SERVER_PROVISIONING: 'Server provisioning',
      DISTRIBUTED_STORAGE: 'Distributed Storage',
      OBJECT_STORAGE: 'Object Storage',
      OWNER: 'Betreiber',
      CERTIFICATION_LIST: 'Zertifizierung',
      OPERATOR_CERTIFICATION_LIST: 'Betreiber Zertifizierung',
      SHARING: 'Freigabe',
      SHARING_ENABLED: 'Freigegebene Location',
      SHARING_DISABLED: 'Nicht freigegebene Location',
    },
    RESULTS: {
      HEADLINE: 'Locations ({{ numResults }} Ergebnisse)',
      HEADLINE_SINGLE: 'Locations ({{ numResults }} Ergebnis)',
      RESET_FILTERS: 'Filter zurücksetzen',
      DETAILS: 'Details',
      OWNER: 'Betreiber',
      OWNER_WEBSITE: 'Webseite',
      NO_RESULTS_HEADLINE: 'Keine Locations gefunden',
      NO_RESULTS_TEXT:
        'Es tut uns leid, wir konnten keine Location mit den gesetzen Filtern finden. Wir arbeiten stetig daran neue Locations für unsere Kunden freizuschalten.',
      ENABLE_SHARING: 'Location für deine Accounts freigeben',
    },
    "HIGH_PERFORMANCE_LOCATION": 'Dieser Standort ist mit hochleistungsfähiger Hardware ausgestattet.'
  },
  CONTACT_FORM: {
    INTRO: 'Wir freuen uns auf deine Nachricht!',
    INTRO_PRE_EMAIL: 'Schreibe uns eine E-Mail an',
    INTRO_POST_EMAIL:
      'oder öffne jetzt dein Standard E-Mail Programm, wenn du eines eingerichtet hast.',
    OPEN_EMAIL_CLIENT: 'Öffne deinen E-Mail Client',
    HEADLINE: 'Kontaktiere uns',
  },
  LOCATION_SELECT: {
    HINT_SWITCHED_OFF:
      'Dieser Standort wurde in den <a href="/Partner/Accounts/Settings/Locations">globalen Einstellungen</a> für alle Konten deaktiviert. Wählst du den Standort trotzdem für das erste Projekt, aktivieren wir ihn entsprechend in den kontoeigenen Einstellungen des neuen Kontos. Damit werden für dieses Konto nicht die Standardeinstellungen genutzt.',
  },
};
