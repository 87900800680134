import { Injectable, EventEmitter } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import * as _ from 'lodash';

import { de } from './de';
import { en } from './en';

const availableLanguages: any = {
  'de' : de,
  'en' : en
};

export interface TranslationChangeEvent {
  translations: any;
  lang: string;
}

export interface LangChangeEvent {
  lang: string;
  translations: any;
}

export interface DefaultLangChangeEvent {
  lang: string;
  translations: any;
}


@Injectable({
  providedIn: 'root'
})
export class UiTranslateService {
  private onTranslationChangeEmitter = new EventEmitter();
  private onLangChangeEmitter = new EventEmitter();
  private onDefaultLangChangeEmitter = new EventEmitter();
  private templateMatcher: RegExp = /{{\s?([^{}\s]*)\s?}}/g;

  defaultLang = 'en';
  currentLang = this.defaultLang;

  constructor() {
    registerLocaleData(localeDe, 'de-DE');
  }

  /**
   *
   */
  get onTranslationChange(): EventEmitter<TranslationChangeEvent> {
    return this.onTranslationChangeEmitter;
  }

  /**
   * An EventEmitter to listen to lang change events
   */
  get onLangChange(): EventEmitter<LangChangeEvent> {
    return this.onLangChangeEmitter;
  }

  /**
   * An EventEmitter to listen to default lang change events
   */
  get onDefaultLangChange(): EventEmitter<DefaultLangChangeEvent> {
    return this.onDefaultLangChangeEmitter;
  }





  /**
   * an array of langs
   */
  get langs(): string[] {
    return Object.keys(availableLanguages);
  }

  set langs(langs: string[]) {
    // not allowed
  }

  /**
   * a list of translations per lang
   */
  get translations(): any {
    return availableLanguages;
  }

  set translations(translations: any) {
    // not allowed
  }

  private interpolate(expr: string | ((...args: any[]) => string), params?: any): string {
    let result: string;

    if (typeof expr === 'string') {
      result = this.interpolateString(expr, params);
    } else if (typeof expr === 'function') {
      result = this.interpolateFunction(expr, params);
    } else {
      // this should not happen, but an unrelated TranslateService test depends on it
      result = expr as string;
    }

    return result;
  }

  private interpolateFunction(fn: (...args: any[]) => string, params?: any) {
    return fn(params);
  }

  private interpolateString(expr: string, params?: any) {
    if (!params) {
      return expr;
    }

    return expr.replace(this.templateMatcher, (substring: string, b: string) => {
      const r = this.getValue(params, b);
      return r !== undefined ? r : substring;
    });
  }

  getValue(target: any, key: string): any {
    const keys = typeof key === 'string' ? key.split('.') : [key];
    key = '';
    do {
      key += keys.shift();
      if (target !== undefined && target[key] !== undefined && (typeof target[key] === 'object' || !keys.length)) {
        target = target[key];
        key = '';
      } else if (!keys.length) {
        target = undefined;
      } else {
        key += '.';
      }
    } while (keys.length);

    return target;
  }


  /**
   * Returns the parsed result of the translations
   */
  public getParsedResult(translations: any, key: any, interpolateParams?: object): any {
    let res: string | undefined;

    if (key instanceof Array) {
      const result: any = {};
      for (const k of key) {
        result[k] = this.getParsedResult(translations, k, interpolateParams);
      }
      return result;
    }

    if (translations) {
      res = this.interpolate(this.getValue(translations, key), interpolateParams);
    }

    if (typeof res === "undefined" && this.defaultLang != null && this.defaultLang !== this.currentLang) {
      res = this.interpolate(this.getValue(this.translations[this.defaultLang], key), interpolateParams);
    }

    if (typeof res === "undefined") {
      // TODO: implement missing translation handler

    }

    return typeof res !== "undefined" ? res : key;
  }





  /**
   * Returns a translation instantly from the internal state of loaded translation.
   * All rules regarding the current language, the preferred language of even fallback languages will be used except any promise handling.
   */
  public instant(key: string | Array<string>, interpolateParams?: object): string | any {
    if (key === undefined || !key.length) {
      throw new Error(`Parameter "key" required`);
    }

    return this.getParsedResult(availableLanguages[this.currentLang], key, interpolateParams);
  }




}
