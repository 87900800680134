import { Component, Input, Output, EventEmitter, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges, AfterViewInit, ElementRef, ViewChild, Provider } from '@angular/core';
import { Button } from 'primeng/button';

const providers: Provider[] = [];
declare type ButtonIconPosition = 'left' | 'right' | 'top' | 'bottom';

@Component({
  selector: 'ig-button',
  template: `
    <button
      [attr.data-btn-e2e]="e2e"
      [attr.type]="type"
      [class]="styleClass"
      [ngStyle]="style"
      [disabled]="disabled"
      [attr.aria-disabled]="disabled || fakeDisabled"
      [ngClass]="{
        'p-button p-component': true,
        'p-button-icon-only': icon && !label,
        'p-button-sm': size === 'small',
        'p-button-lg': size === 'large',
        'p-button-secondary p-button-outlined': mode === 'secondary',
        'p-button-success': mode === 'cta',
        'p-button-danger': mode === 'danger',
        'p-button-loading': loading,
        'p-button-disabled': disabled || fakeDisabled,
        'p-button-fake-disabled': fakeDisabled,
        'p-button-vertical': (iconPos === 'top' || iconPos === 'bottom') && label
      }"
      (click)="disabled ? noop() : onClick.emit($event)"
      (focus)="onFocus.emit($event)"
      (blur)="onBlur.emit($event)"
      #buttonEl
      pRipple
    >
      <ng-content></ng-content>
      <ng-container *ngIf="contentTemplate">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </ng-container>
      <span
        [ngClass]="{
          'p-button-icon': true,
          'p-button-icon-left': iconPos === 'left' && label,
          'p-button-icon-right': iconPos === 'right' && label,
          'p-button-icon-top': iconPos === 'top' && label,
          'p-button-icon-bottom': iconPos === 'bottom' && label
        }"
        [class]="icon"
        *ngIf="icon"
        [attr.aria-hidden]="true"
      ></span>
      <span class="p-button-label" [attr.aria-hidden]="icon && !label" *ngIf="label">{{ label }}</span>
      <span [ngClass]="badgeStyleClass()" *ngIf="badge" [class]="badgeClass || ''">{{ badge }}</span>
    </button>
  `,
  providers: providers,
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations:
    (Button.prototype.constructor as any).__annotations__ &&
      (Button.prototype.constructor as any).__annotations__.length &&
      (Button.prototype.constructor as any).__annotations__.animations
      ? (Button.prototype.constructor as any).__annotations__[0].animations
      : []
})
export class ButtonComponent extends Button implements OnChanges, AfterViewInit {
  @HostBinding('attr.data-e2e-mode') e2eMode = 'primary';
  @ViewChild('buttonEl') buttonEl?: ElementRef<HTMLButtonElement>;

  /**
   * is button loading?
   */
  @Input() loading = false;

  /**
   * Add e2e tag for Buttons
   */
  @HostBinding('attr.data-e2e')
  @Input() e2e?: string;

  /**
   * type of button (button, submit, reset, search)
   */
  @Input() type = 'button';

  /**
   * modes of button (primary,cta, secondary, )
   */
  mode = 'primary';
  @Input('mode') set _mode(value: string) {
    this.mode = value;
    this.e2eMode = value;
  }

  /**
   * type of button (default, big, small)
   */
  @Input() size: "small" | "large" | undefined;

  /**
   * position of the icon
   */
  @Input() override iconPos: ButtonIconPosition = 'left';

  /**
   * icon class (from primeicons or fontawesome)
   */
  @Input() icon = '';

  /**
   * label of the button
   */
  @Input() label = '';

  /**
   * is button disabled?
   */
  @Input() disabled = false;

  /**
   * fake disable button (only applies disabled class, button is still clickable)
   */
  @Input() fakeDisabled?: boolean;

  /**
   * inline style rules for the button
   */
  @Input() style = {};

  /**
   * style classes for the button (used for the severities etc.)
   */
  @Input() styleClass = '';

  /**
   * sets the focus to this button
   */
  @Input() autoFocus = false;

  /**
   * emitted on button click
   */
  @Output() override onClick: EventEmitter<any> = new EventEmitter();

  /**
   * emitted on focus in
   */
  @Output() override onFocus: EventEmitter<any> = new EventEmitter();

  /**
   * emitted on focus out
   */
  @Output() override onBlur: EventEmitter<any> = new EventEmitter();

  noop() { }

  constructor(private readonly ref: ChangeDetectorRef, private readonly elem: ElementRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['loading'] || changes['icon'] || changes['label'] || changes['disabled'] || changes['style'] || changes['styleClass']) {
      this.ref.markForCheck();
    }
  }

  ngAfterViewInit(): void {
    // check for native `disabled` attribute once and set the internal `disabled` attribute then
    if (this.elem.nativeElement && (this.elem.nativeElement as HTMLElement).hasAttribute('disabled')) {
      this.disabled = true;
      this.ref.markForCheck();
    }

    if (this.autoFocus) {
      window.setTimeout(() => {
        if (this.buttonEl?.nativeElement) {
          this.buttonEl.nativeElement.focus();
        }
      }, 100);
    }
  }
}
