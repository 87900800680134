import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'upperfirst'
})
export class UpperfirstPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    return _.upperFirst(value);
  }
}
