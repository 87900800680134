import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ZIndexUtils } from 'primeng/utils';
import { PrimeNGConfig } from 'primeng/api';
import * as _ from 'lodash';

export enum LoaderSize {
  fullscreen = 'fullscreen',
  parentSize = 'parentSize',
  inline = 'inline',
}


@Component({
  selector: 'ig-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  /**
   * the size of the loader
   */
  @Input() size: LoaderSize | string = LoaderSize.inline;

  /**
   * custom loader size (defaults to 'inherit' when `size` set to 'inline')
   */
  @Input() customSize?: string;

  /**
   * optional message, shown below the loader (not when `size` set to 'inline')
   */
  @Input() message?: string;

  /**
   * hide the loader
   */
  @Input() hidden = false;

  /**
   * get a white loader for dark backgrounds
   */
  @Input() white = false;

  /**
   * use the newer style
   */
  @Input() newStyle = false;

  @ViewChild('container') containerEl?: ElementRef<HTMLElement>;


  /**
   * @ignore
   */
  style: any = {};

  hasCustomSize = false;


  private rootEl: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private zone: NgZone,
    private readonly ref: ChangeDetectorRef,
    public config: PrimeNGConfig
  ) { }

  /**
   * @ignore
   */
  ngOnInit(): void {

    if (this.size === LoaderSize.fullscreen) {
      this.zone.runOutsideAngular(() => {
        // append the component with a "dimmer" to the dom

        // but first ensure we only have a single element
        const clones = document.getElementsByTagName(this.el.nativeElement.tagName);
        _.forEach(clones, clone => {
          if (clone && clone !== this.el.nativeElement && clone.querySelector) {
            if (clone.querySelector('.ig-loader-container.fullscreen')) {
              this.renderer.removeChild(clone.parentNode, clone);
            }
          }
        });

        // get possible root (we need to place it as much on the top as possible in the DOM but better within angular controlled areas)
        let root: any = document.getElementsByTagName('app-root');
        if (root.length === 1) {
          this.rootEl = root[0];
        } else {
          root = document.getElementById('root');
          if (root) {
            this.rootEl = root;
          } else {
            this.rootEl = document.body;
          }
        }

        // place the overlay bg and append our component directly to it
        this.renderer.appendChild(this.rootEl, this.el.nativeElement);

        this.zone.run(() => {
          this.setSize();
        });
      });
    } else {
      this.setSize();
    }

  }

  ngAfterViewInit(): void {
    ZIndexUtils.set('ig-loader', this.containerEl?.nativeElement, this.config.zIndex.modal);

  }

  /**
   *
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['customSize'] || changes['size']) {
      this.setSize();
    }

    if (changes['hidden']) {
      if (!this.hidden) {
        ZIndexUtils.set('ig-loader', this.containerEl?.nativeElement, this.config.zIndex.modal);
      }
      this.ref.markForCheck();
    }
  }

  ngOnDestroy() {
    if (this.size === LoaderSize.fullscreen && this.rootEl) {
      this.renderer.removeChild(this.rootEl, this.el.nativeElement);
    }
  }

  private setSize() {
    if (this.customSize) {
      this.hasCustomSize = true;

      this.style.width = this.customSize;
      this.style.height = this.customSize;
      this.style.fontSize = this.customSize;
      if (this.customSize.match(/^([0-9]+)([a-zA-Z]+)$/)) {
        this.style.borderWidth = String((parseInt(RegExp.$1, 10) * 0.1) + RegExp.$2);
      } else {
        // we assume 'px'
        this.style.borderWidth = String(Math.round(parseInt(this.customSize, 10) * 0.1) + 'px');
      }

    } else {
      this.hasCustomSize = false;

      this.style.width = undefined;
      this.style.height = undefined;
      this.style.fontSize = undefined;
      this.style.borderWidth = undefined;
    }

    this.ref.markForCheck();
  }

}
