import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from './tooltip.directive';
import { SharedModule } from '../../helper/shared.module';

@NgModule({
  declarations: [TooltipDirective],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [TooltipDirective]
})
export class TooltipModule { }
