import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, Output, Renderer2, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { UiTranslateService } from '../../helper/lang/ui-translate.service';
import { MarkdownPipe } from '../../helper/pipes/markdown.pipe';
import { ZIndexUtils } from 'primeng/utils';
import { PrimeNGConfig } from 'primeng/api';

@Directive({
  selector: '[igTooltip]',
  providers: [
    MarkdownPipe
  ]
})
export class TooltipDirective implements OnChanges {
  // tslint:disable-next-line: no-input-rename
  @Input('igTooltip') tooltipTitle?: string;

  /**
   * condition to display tooltip, checks if the attribute with the value is given on the element
   */
  @Input() igTooltipCondition?: Record<string, string>;

  /**
   * Defines the position of the tooltip. Possible values are `top`, `bottom`, `left`, `right`
   * There is no collision detection.
   */
  @Input() tooltipPosition = 'top';

  /**
   * Tooltip modes
   * 'default' = full opaque tooltip with primary color as background and box shadow
   * 'discreet' = half transparent tooltip, with black background and without shadow <---- still used?
   * 'success' = full opaque tooltip with success colour as background and box-shadow
   */
  @Input() tooltipMode = 'default';

  /**
   * @deprecated you can now use markdown in the tooltip which is preferred
   * render the tooltip content as HTML
   */
  @Input() tooltipAllowHtml = false;

  @Input() tooltipAppendTo: string | HTMLElement = 'body';

  /**
   * activate the experimental screen edge detection, preventing the tooltip to stick on the right screen edge
   * (only for `tooltipPosition=top`)
   */
  @Input() tooltipExperimentalScreenEdgeDetection = false;

  /**
   * add a link to the tooltip content
   */
  @Input() tooltipLinkTo?: string;

  /**
   * the text showing up for the link (only with `tooltipLinkTo`)
   */
  @Input() tooltipLinkText?: string;

  /**
   * optional constrain the width of the tooltip to this (e.g. "400px" or "50vw")
   */
  @Input() tooltipMaxWidth?: string;

  /**
   * Whether the z-index should be managed automatically to always go on top or have a fixed value.
   */
  @Input() tooltipZIndex = 'auto';

  @Output() linkClick = new EventEmitter<void>();

  private tooltip?: HTMLElement;
  private tooltipRendered = false;

  private mouseover = false;

  /**
   * @ignore
   */
  @HostListener('mouseover') onMouseEnter() {
    if (this.mouseover) {
      return;
    }

    this.mouseover = true;
    this.removeTooltip();
    this.showTooltip();
  }

  /**
   * @ignore
   */
  @HostListener('mouseleave') onMouseLeave() {
    this.mouseover = false;
    if (this.tooltip) {
      setTimeout(() => {
        if (this.tooltip?.matches(':hover')) {
          const myInt = window.setInterval(() => {
            if (!this.tooltip?.matches(':hover')) {
              this.removeTooltip();
              window.clearInterval(myInt);
            }
          }, 200);
        } else {
          this.removeTooltip();
        }
      }, 200);
    }

  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private readonly translateService: UiTranslateService,
    private markdownPipe: MarkdownPipe,
    public config: PrimeNGConfig
  ) {
    // test if the target element is still in DOM otherwise remove the tooltip also
    const myInt = window.setInterval(() => {
      if (!document.body.contains(this.el.nativeElement)) {
        this.removeTooltip();
        window.clearInterval(myInt);
      }
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.tooltipRendered) {
      if (changes['tooltipMode']) {
        if (changes['tooltipMode'].previousValue) {
          this.renderer.removeClass(this.tooltip, 'ig-tooltip-mode-' + changes['tooltipMode'].previousValue);
        }
        this.renderer.addClass(this.tooltip, 'ig-tooltip-mode-' + changes['tooltipMode'].currentValue);
      }
      if (changes['tooltipTitle'] && this.tooltip) {
        this.tooltip.textContent = '';

        this.updateContent();
        this.updatePosition(true);

        if (changes['tooltipTitle'].currentValue === null || changes['tooltipTitle'].currentValue === undefined) {
          this.removeTooltip();
        }
      }

      if (changes['tooltipPosition']) {
        this.updatePosition(true);
      }
    }
  }

  private showTooltip() {
    if (this.tooltipTitle === undefined) {
      return;
    }
    if (this.igTooltipCondition) {
      let show = false;
      _.forEach(this.igTooltipCondition, (attrValue, attrName) => {
        if (this.el.nativeElement[attrName] === attrValue || attrValue === (this.el.nativeElement as HTMLElement).getAttribute(attrName)) {
          show = true;
        }
      });
      if (!show) {
        return;
      }
    }
    this.tooltip = this.renderer.createElement('span');
    this.tooltip = this.tooltip!;
    // creating a span
    this.tooltip.appendChild(this.renderer.createElement('span'));
    // appending a span to the tooltip

    if (this.tooltipZIndex === 'auto') ZIndexUtils.set('ig-tooltip', this.tooltip, this.config.zIndex.tooltip);
    else this.tooltip.style.zIndex = this.tooltipZIndex;

    this.updateContent();

    // append the tip to body
    const target = this.tooltipAppendTo === 'body' ? document.body : this.tooltipAppendTo;
    this.renderer.appendChild(target, this.tooltip);

    this.updatePosition();

    // appending to the document
    this.renderer.addClass(this.tooltip, 'ig-tooltip');
    this.renderer.addClass(this.tooltip, 'ig-tooltip-pos-' + this.tooltipPosition);
    this.renderer.addClass(this.tooltip, 'ig-tooltip-mode-' + this.tooltipMode);
    if (this.tooltipMaxWidth !== undefined) {
      this.renderer.addClass(this.tooltip, 'ig-tooltip-constrain-width');
    }
    // adding the tooltip styles

    if ( this.tooltip && this.tooltip.clientWidth >= 320 ) {
      this.renderer.addClass(this.tooltip, 'ig-tooltip-large');
    }

  }

  private updateContent() {
    if (this.tooltipAllowHtml) {
      const htmlContainer = this.renderer.createElement('div');
      htmlContainer.innerHTML = this.tooltipTitle;
      this.renderer.appendChild(this.tooltip, htmlContainer);
    } else {
      const htmlContainer = this.renderer.createElement('div');
      htmlContainer.innerHTML = this.markdownPipe.transform(this.tooltipTitle, 'inline', false);
      this.renderer.appendChild(this.tooltip, htmlContainer);
    }

    // adding the tooltip link if there is one
    if (this.tooltipLinkTo) {
      const link = this.renderer.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', this.tooltipLinkTo);
      link.setAttribute('style', 'display: block');
      link.innerHTML = this.tooltipLinkText ? this.tooltipLinkText + ' <i class="icon-a106_linkextern"></i>' : this.translateService.instant('GENERAL.FIND_OUT_MORE') + ' <i class="icon-a106_linkextern"></i>';
      this.renderer.appendChild(this.tooltip, link);

    } else if (this.tooltipLinkText) {
      const link = this.renderer.createElement('a');
      link.setAttribute('style', 'display: block; cursor: pointer');
      link.innerHTML = this.tooltipLinkText;
      link.addEventListener('click', () => {
        this.removeTooltip();
        this.linkClick.emit();
      });
      this.renderer.appendChild(this.tooltip, link);
    }
  }

  private updatePosition(fast = false) {
    let hostPos = this.el.nativeElement.getBoundingClientRect();

    if (this.tooltipMaxWidth !== undefined) {
      this.renderer.setStyle(this.tooltip, 'maxWidth', this.tooltipMaxWidth);
    } else {
      this.renderer.setStyle(this.tooltip, 'maxWidth', `${Math.max(Math.round(hostPos.left / 2), 320)}px`);
    }

    const position = () => {
      // renew the position
      hostPos = this.el.nativeElement.getBoundingClientRect();
      if (!this.tooltip) {
        return;
      }

      const tooltipPos = this.tooltip.getBoundingClientRect();
      if (this.tooltipPosition === 'top') {
        this.renderer.setStyle(this.tooltip, 'top', `${hostPos.top - tooltipPos.height - 16}px`);
        this.renderer.setStyle(this.tooltip, 'left', `${hostPos.left + Math.round(hostPos.width / 2) - Math.round(tooltipPos.width / 2)}px`);

        if (this.tooltipExperimentalScreenEdgeDetection) {
          const tooltipPos2 = this.tooltip.getBoundingClientRect();
          // check if the tooltip sticks on the right screen edge
          if (tooltipPos2.right >= document.body.offsetWidth) {
            // if so, reduce the width by 20px...
            this.renderer.setStyle(this.tooltip, 'width', `${Math.round(tooltipPos2.width - 20)}px`);
            // ... and recalc the left position
            const tooltipPos3 = this.tooltip.getBoundingClientRect();
            this.renderer.setStyle(this.tooltip, 'left', `${hostPos.left + Math.round(hostPos.width / 2) - Math.round(tooltipPos3.width / 2)}px`);
          }
        }
      }
      if (this.tooltipPosition === 'bottom') {
        this.renderer.setStyle(this.tooltip, 'top', `${hostPos.bottom + 16}px`);
        this.renderer.setStyle(this.tooltip, 'left', `${hostPos.left + Math.round(hostPos.width / 2) - Math.round(tooltipPos.width / 2)}px`);
      }
      if (this.tooltipPosition === 'left') {
        this.renderer.setStyle(this.tooltip, 'top', `${hostPos.top + Math.round(hostPos.height / 2) - Math.round(tooltipPos.height / 2)}px`);
        this.renderer.setStyle(this.tooltip, 'left', `${hostPos.left - tooltipPos.width - 16}px`);
      }
      if (this.tooltipPosition === 'right') {
        this.renderer.setStyle(this.tooltip, 'top', `${hostPos.top + Math.round(hostPos.height / 2) - Math.round(tooltipPos.height / 2)}px`);
        this.renderer.setStyle(this.tooltip, 'left', `${hostPos.right + 16}px`);
      }
      this.renderer.addClass(this.tooltip, 'ig-tooltip-visible');
      this.tooltipRendered = true;
    };

    if (fast) {
      position();
    } else {
      window.setTimeout(() => position(), 100);
    }
  }

  private removeTooltip() {
    if (this.tooltip && this.tooltip.parentNode) {
      this.renderer.removeChild(this.tooltip.parentNode, this.tooltip);
      this.tooltipRendered = false;
    }
  }
}
