import { Injectable } from '@angular/core';

import { find } from 'lodash';
import { contractmanagement_api } from '@gridscale/gs-services';

const ENABLED_PARTNERS = ['41a2a1bf-6a97-4f31-a162-d12c4e645f17', '618ab40c-39ad-46bd-9855-626c91c0aa03', 'd8beadab-80a1-4e44-bcaf-000185ffde3f', '37729794-35d1-4296-8bcb-41a1ef81aa31', '2441ce19-5eb0-443f-a9c5-fa6d12308795', '60feb512-ee93-4061-a372-ea195fd489e1', '64e1218c-8012-4624-8c99-e1fdbfc90518'];

/**
 * The Currency Mocks the currency Handling for Templates, as long as there is no API Solution it will use the the Name of a Template to hide the currency.
 *
 *
 */
@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  storeSessionSubscription: any;
  storeSubscription: any;
  defaultCurrency = 'eur';
  currencies = [
    { label: 'Euro (€)', value: 'eur', symbol: '€' },
    { label: 'Schweizer Franken (CHF)', value: 'chf', symbol: 'CHF' }
  ];

  #currentPartnerUuid?: string;

  setCurrentPartnerUuid(uuid: string) {
    this.#currentPartnerUuid = uuid;
  }

  /**
   * Returns availible Currencies
   */
  getCurrencies() {
    return this.currencies;
  }

  /**
   * Returns the Label/Name of the currency
   *
   * @param _currency currency value (eur|chf)
   */
  getCurrencyLabel(_currency: string) {
    const currency = find(this.currencies, { value: _currency });
    if (currency) {
      return currency.label;
    }

    return _currency;
  }

  /**
   * Returns the currency Symbol
   *
   * @param _currency currency value (eur|chf)
   */
  getCurrencySymbol(_currency: string) {
    const currency = find(this.currencies, { value: _currency });
    if (currency) {
      return currency.symbol;
    }

    return '€';
  }

  /**
   * Checks  if the current partner is allowed to use Multicurrency.
   *
   * For now, we have a list of partner_uuid's
   *
   */
  hasMultiCurrency() {
    if (this.#currentPartnerUuid) {
      // Check if Partner is allowed to have multicurrency
      if (ENABLED_PARTNERS.indexOf(this.#currentPartnerUuid) >= 0) {
        return true;
      }
    }
    return false;
  }

  /**
   * This FUnction will extrect the currency Code from a Proce Template. If there is none it will use the Default
   *
   * @param _name Name of a Price Template
   */
  getCurrencyForName(_name: string) {
    let returnValue = this.defaultCurrency;
    if (_name && _name.length > 0) {
      this.currencies.forEach((_currency: any) => {
        if (_name.search('##' + _currency.value + '##') >= 0) {
          returnValue = _currency.value;
        }
      });
    }

    return returnValue;
  }

  /**
   * This Function will create the new Tempalte Name with the Currency appended
   *
   * @param _name Name of the Template
   * @param _currency Currency Code to add
   */
  setCurrencyForName(_name: string, _currency: string) {
    return this.cleanName(_name) + '##' + _currency + '##';
  }

  /**
   *
   * @param _name Clean the ##CCC## from the Template Name
   */
  cleanName(_name: string) {
    if (!_name || _name.length <= 0) {
      return _name;
    }
    return _name.replace(/##[a-zA-Z0-9]{3}##/g, '');
  }

  /**
   * Takes a Currency ID and extracts the Currency
   *
   * @param _template_uuid Template UUID
   */
  getCurrencyForTemplate(_template: contractmanagement_api.PriceListDetailResponse) {
    if (_template) {
      return this.getCurrencyForName(_template.data.attributes.name);
    }

    return this.defaultCurrency;
  }
}
