<div class="ig-loader-container" [ngClass]="{ fullscreen: size === 'fullscreen', inline: size === 'inline', 'parent-size': size === 'parentSize', 'has-customsize': hasCustomSize }" *ngIf="!hidden" #container>
  <div class="ig-loader-bg" *ngIf="size === 'fullscreen' || size === 'parentSize'"></div>

  <div class="ig-loader-inner-container">
    <div *ngIf="size !== 'inline'">
      <!-- <i class="fad fa-spinner-third loader-icon" [class.size-inline]="size === 'inline'" [ngStyle]="style"></i> -->

      <div [class.loading-spinner]="!newStyle" [class.loading-spinner-new]="newStyle" [class.white]="white" [ngStyle]="style">
        <ng-container *ngIf="newStyle"><div></div><div></div><div></div><div></div></ng-container>
      </div>

      <p class="loader-message" *ngIf="message">{{ message }}</p>
    </div>
    <!-- <i *ngIf="size === 'inline'" class="fad fa-spinner-third loader-icon size-inline" [ngStyle]="style"></i> -->
    <div *ngIf="size === 'inline'" class="inline-container" [ngStyle]="style">
      <div [class.loading-spinner]="!newStyle" [class.loading-spinner-new]="newStyle" class="size-inline" [class.white]="white">
        <ng-container *ngIf="newStyle"><div></div><div></div><div></div><div></div></ng-container>
      </div>
    </div>

  </div>
</div>
