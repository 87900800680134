import { NgZone, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { marked } from 'marked';
import * as _ from 'lodash';

/**
 * a markdown pipe - transforms markdown to html
 * remember you need to use this pipe in an `[innerHTML]` binding in most cases, otherwise html will get escaped
 */

@Pipe({
  name: 'markdown'
})
export class MarkdownPipe implements PipeTransform {

  constructor(private readonly sanitizer: DomSanitizer, private readonly zone: NgZone, private readonly router: Router) {
    // we once register a window function which uses the angular router for navigating to internal links
    if (_.get(window, ['gsRouterLink']) === undefined) {
      this.zone.runOutsideAngular(() => {
        _.set(window, ['gsRouterLink'], (routerUrl: string) => {
          this.zone.run(() => {
            this.router.navigate(routerUrl.split('/'));
          })
        });
      });
    }
  }

  /**
   * transforms a string with markdown to html string
   * mode: 'full' transforms a whole markdown document with paragraphs (# headline), 'inline' does not transform headlines, only inner markdown (e.g. **xxx**)
   */
  transform(value: any, mode: 'full' | 'inline' = 'full', bypassSecurity = true): any {
    if (value && value.length > 0) {
      marked.use({
        renderer: {
          link(href, title, text) {

            if (href) {
              if (href.startsWith('mailto:')) {
                return text;
              } else {
                const a = document.createElement('a');
                a.setAttribute('href', href);
                if (title && title !== '') {
                  a.setAttribute('title', title);
                }
                a.textContent = text;

                if (href.match(/^[a-z]*:?\/\//)) {
                  // external link - open in new window and prepend with an "external link" icon
                  a.setAttribute('target', '_blank');
                  const icon = document.createElement('i');
                  icon.setAttribute('class', 'fal fa-external-link');
                  a.prepend(document.createTextNode('\u00A0'));
                  a.prepend(icon);
                  icon.style.marginLeft = '0.5em';

                } else {
                  // internal link, use the angular router via the window function we registered in the constructor
                  a.setAttribute('href', 'javascript:window.gsRouterLink("' + href + '")');
                }

                return a.outerHTML;
              }
            }
            return '';
          },
        }
      });

      const parseOptions = {
        breaks: true,
        mangle: false
      };

      const parsedValue = mode === 'inline'
        ? marked.parseInline(value, parseOptions) as string
        : marked.parse(value, parseOptions);

      if (bypassSecurity) {
        return this.sanitizer.bypassSecurityTrustHtml(parsedValue);
      }

      return parsedValue;
    }
    return value;
  }

}
